<template>
  <div class="container-fluid p-0" v-if="content != null">
    <div class="detail-wrap collapsed">
      <div class="detail-original-wrap">
        <div class="detail-collapse-wrap">
          <button type="button" class="btn-detail-collapse"><i class="xi-angle-left-min"></i></button>
        </div>
        <div class="d-flex flex-wrap align-items-center py-9">
          <h2 class="text-gray-600 mr-24">{{ content.original.name }}</h2>
          <!-- <a href="javascript:void(0)" class="btn-icon btn-sync fs-14">
            <i class="xi-renew fs-18 mr-4"></i>
            <span>{{ content.original.updated_at }}에 동기화</span>
          </a> -->
        </div>
        <div class="d-flex flex-wrap align-itens-center justify-content-between mb-12">
          <ul class="tag-wrap text-gray-500"></ul>
          <div class="d-flex align-items-center" v-if="content.original.updated_at != null">
            <i class="xi-time fs-18 mr-4"></i>
            <p class="p3 font-weight-normal">{{ content.original.updated_at }}에 동기화</p>
          </div>
        </div>
        <div class="detail-readonly-tab mb-32">
          <span class="nav-item">원본 콘텐츠</span>
        </div>
        <div class="detail-readonly-group mb-32" v-if="content.original.mfds_id != null">
          <span class="label">식약처 고지 콘텐츠</span>
          <pre class="detail-readonly">{{ content.original.mfds_content }}</pre>
        </div>
        <div class="detail-readonly-group mb-32">
          <span class="label">iF DB 콘텐츠</span>
          <pre class="detail-readonly">{{ content.original.ifdb_content }}</pre>
        </div>
      </div>
      <div class="detail-new-wrap">
        <input type="text" name="title" id="idTitle" class="form-control detail-title mb-12" placeholder="콘텐츠 이름을 입력해주세요." v-model="content.service_data.name" :disabled="activeBtnText == '' || activeBtnText == '반영요청'">
        <div class="d-flex flex-wrap align-itens-center justify-content-between mb-12">
          <ul class="tag-wrap"></ul>
          <div class="d-flex align-items-center" v-if="content.service_data.request_time != null">
            <i class="xi-time fs-18 mr-4"></i>
            <p class="p3 font-weight-normal">{{ content.service_data.request_time }}에 수정</p>
          </div>
        </div>
        <ul class="nav nav-tabs detail-tab mb-12">
          <li class="nav-item mb-12" :key="index" v-for="(item, index) in serviceList">
            <a :href="'#idDetailTab_' + item.id" data-toggle="tab" class="nav-link" :class="{active:$route.params.serviceId == item.id}" @click="getContent(item.id)">{{ item.name }}</a>
          </li>
        </ul>
        <div class="tab-content">
          <!-- <div :id="'idDetailTab_' + item.id" class="tab-pane fade show" :class="{active:content.service_data.service == item.id}" :key="index" v-for="(item, index) in content.services"> -->
          <div class="tab-pane show active">
            <div class="form-group mb-32" v-if="content.service_data.mfds_id != null">
              <div class="d-flex align-items-center justify-content-between mb-10">
                <label for="idMfdsContent" class="mb-0">식약처 고지 콘텐츠</label>
                <button type="button" class="text-primary" v-if="activeBtnText != '' && activeBtnText != '반영요청'" @click="getGrammar('idMfdsContent')">맞춤법 검사하기</button>
              </div>
              <textarea name="mfdsContent" id="idMfdsContent" class="form-control" data-grammar="grammarMfds" placeholder="식약처 고지 콘텐츠를 입력해주세요." v-model="content.service_data.mfds_content" :disabled="activeBtnText == '' || activeBtnText == '반영요청'"></textarea>
              <div class="grammar-wrap" :class="{'is-valid': grammarMfdsValid}" v-if="grammarMfds != ''">
                <pre v-html="grammarMfds"></pre>
              </div>
            </div>
            <div class="form-group mb-32">
              <div class="d-flex align-items-center justify-content-between mb-10">
                <label for="idIfdbContent" class="mb-0">iF DB 콘텐츠</label>
                <button type="button" class="text-primary" v-if="activeBtnText != '' && activeBtnText != '반영요청'" @click="getGrammar('idIfdbContent')">맞춤법 검사하기</button>
              </div>
              <textarea name="ifdbContent" id="idIfdbContent" class="form-control" data-grammar="grammarIfdb" placeholder="iF DB 콘텐츠를 입력해주세요." v-model="content.service_data.ifdb_content" :disabled="activeBtnText == '' || activeBtnText == '반영요청'"></textarea>
              <div class="grammar-wrap" :class="{'is-valid': grammarIfdbValid}" v-if="grammarIfdb != ''">
                <pre v-html="grammarIfdb"></pre>
              </div>
              <div class="textarea-comment-wrap" v-if="isActiveIfdbComment">
                <textarea name="ifdbComment" id="idifdbComment" class="form-control" placeholder="코멘트를 남겨주세요." v-model="content.service_data.ifdb_comment" :disabled="$store.getters.userInfo.group_name != 'dif'"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-wrap">
          <a href="javascript:void(0)" class="btn btn-outline small" @click="$router.push({name: 'nutrientList'})">목록으로</a>
          <a href="javascript:void(0)" class="btn btn-outline small" @click="updateContent('refuse')" v-if="$store.getters.userInfo.group_name == 'dif' && (content.service_data.review_status == 'assessment' || content.service_data.review_status == 'reassessment')">반려하기</a>
          <a href="javascript:void(0)" class="btn btn-primary small" @click="updateContent" v-if="activeBtnText != ''" v-text="activeBtnText"></a>
        </div>
      </div>
    </div>
    <ImageUploadModal :title="'\'' + content.service_data.name + '\' 사진 업로드'" :serviceId="content.service_data.service"/>
  </div>
</template>

<script>
import ImageUploadModal from "@/components/modal/ImageUploadModal.vue";
export default {
    name: "AppDetail",
    data() {
      return {
        serviceList: null,
        content: null,
        successMsg: '',
        activeBtnText: '',
        isActiveIfdbComment: true,
        grammarMfds: '',
        grammarMfdsValid: false,
        grammarIfdb: '',
        grammarIfdbValid: false,
      };
    },
    components: {
      ImageUploadModal
    },
    methods: {
      getGrammar(id) {
        let el = document.querySelector(`#${id}`);
        let text = el.value.replaceAll('\n', ' ㅤ ');
        
        if(el.value != null && el.value.trim() != ''){
          this.$bvToast.hide();
          
          this.$axios.get(`/api/grammar?text=${text}`, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.getters.token
            }
          })
          .then((res) => {
            let isPassed = res.data.data[3] == 0;
            let result = res.data.data[2].replaceAll(' ㅤ ', '\n')
                                        .replaceAll("<span class='green_text'>", "<span class='is-invalid' title='잘못된 간격'>")
                                        .replaceAll("<span class='red_text'>", "<span class='is-invalid' title='잘못된 철자'>")
                                        .replaceAll("<span class='purple_text'>", "<span class='is-invalid' title='모호함'>")
                                        .replaceAll("<span class='blue_text'>", "<span class='is-invalid' title='통계 수정'>");
            
            if(el.dataset.grammar == 'grammarMfds') {
              this.grammarMfds = result;
              this.grammarMfdsValid = isPassed;
            } else if(el.dataset.grammar == 'grammarIfdb') {
              this.grammarIfdb = result;
              this.grammarIfdbValid = isPassed;
            }
          })
          // .catch((err) => {
          //   console.log(err.response.data);
          // })
        } else {
          el.focus();
          this.$root.$emit('showToast', 'danger', '내용을 입력해주세요.');
        }
      },
      getServiceList() {
        this.$axios.get('/api/service', {
          headers: {
            'Authorization': 'Bearer ' + this.$store.getters.token
          }
        })
        .then((res) => {
          this.serviceList = res.data.service;
        })
        // .catch(() => {
        //   console.log(err.response.data);
        // });
      },
      getContent(serviceId) {
        this.$utils.startLoading('detail');
        
        let url = '';
        if(serviceId == null) {
          url = `/api/nutrient/${this.$route.params.id}?service=${this.$route.params.serviceId}`;
        } else {
          url = `/api/nutrient/${this.$route.params.id}?service=${serviceId}`;
        }
        
        this.$axios.get(url, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.getters.token
          }
        })
        .then((res) => {
          this.content = JSON.parse(res.data.datas);
          
          if(this.content.service_data.request_time != null) {
            this.content.service_data.request_time = this.$utils.dateFormatter(this.content.service_data.request_time).word;
          }
          this.content.original.updated_at = this.$utils.dateFormatter(this.content.original.updated_at).word;
          
          this.setActiveComment();
          this.setActiveButton();
          this.$utils.endLoading('detail');
        })
        .catch(() => {
          // console.log(err.response.data);
          this.$utils.endLoading('detail');
        })
      },
      updateContent(status) {
        const formData = new FormData();
        formData.append('name', this.content.service_data.name);
        formData.append('name_ko', this.content.service_data.name_ko);
        formData.append('mfds_content', this.content.service_data.mfds_content);
        formData.append('mfds_comment', this.content.service_data.mfds_comment == null ? '' : this.content.service_data.mfds_comment);
        formData.append('ifdb_content', this.content.service_data.ifdb_content);
        formData.append('ifdb_comment', this.content.service_data.ifdb_comment == null ? '' : this.content.service_data.ifdb_comment);
        formData.append('review_status', this.content.service_data.review_status);
        formData.append('service', this.content.service_data.service);

        if(this.$store.getters.userInfo.group_name == 'master') {
          formData.append('review_status', 'reflection');
          formData.append('dsc_comment', '');
          formData.append('rcp_comment', '');
          formData.append('comment', '');
          this.successMsg = '반영이 완료되었습니다.';
        } else if(this.$store.getters.userInfo.group_name == 'dif') {
          if(status == 'refuse') {
            formData.append('review_status', 'refuse');
            this.successMsg = '반려가 완료되었습니다.';
          } else {
            if(this.content.service_data.review_status == 'assessment' || this.content.service_data.review_status == 'reassessment') {
              formData.append('review_status', 'approval');
              this.successMsg = '검토가 완료되었습니다.';
            } else {
              formData.append('review_status', 'refuse');
              this.successMsg = '수정요청이 완료되었습니다.';
            }
          }
        } else if(this.$store.getters.userInfo.group_name == 'design' || this.$store.getters.userInfo.group_name == 'marketing') {
          if(this.content.service_data.review_status == 'approval') {
            formData.append('review_status', 'request_reflection');
            this.successMsg = '반영요청이 완료되었습니다.';
          } else if(this.content.service_data.review_status == 'refuse') {
            formData.append('review_status', 'reassessment');
            this.successMsg = '재검토요청이 완료되었습니다.';
          } else {
            formData.append('review_status', 'assessment');
            this.successMsg = '검토요청이 완료되었습니다.';
          }
        }

        this.$axios.post(`/api/nutrient/${this.content.service_data.ifdb_id}`, formData, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.getters.token
          }
        })
        .then(() => {
          this.getContent(this.content.service_data.service);
          this.$root.$emit('showToast', 'success', this.successMsg);
        })
        // .catch((err) => {
        //   console.log(err.response.data);
        // })
      },
      setActiveButton() {
        if(this.$store.getters.userInfo.group_name == 'master') {
          this.activeBtnText = '반영하기';
          // if(this.content.service_data.review_status == 'request_reflection') {
          //   this.activeBtnText = '반영하기';
          // } else {
          //   this.activeBtnText = '';
          // }
        } else if(this.$store.getters.userInfo.group_name == 'dif') {
          if(this.content.service_data.review_status == 'assessment' || this.content.service_data.review_status == 'reassessment') {
            this.activeBtnText = '검토완료';
          } else {
            this.activeBtnText = '수정요청';
          }
        } else if(this.$store.getters.userInfo.group_name == 'design' || this.$store.getters.userInfo.group_name == 'marketing') {
          if(this.content.service_data.review_status == 'unmodify' || this.content.service_data.review_status == 'reflection') {
            this.activeBtnText = '검토요청';
          } else if(this.content.service_data.review_status == 'refuse') {
            this.activeBtnText = '재검토요청';
          } else if(this.content.service_data.review_status == 'approval') {
            this.activeBtnText = '반영요청';
          }  else {
            this.activeBtnText = '';
          }
        } else {
          this.activeBtnText = '';
        }
      },
      setActiveComment() {
        let ifdbComment = this.content.service_data.ifdb_comment;
        
        if(this.$store.getters.userInfo.group_name != 'dif') {
          if(ifdbComment == null || ifdbComment == '') {
            this.isActiveIfdbComment = false;
          }
        }
      },
      tagValidator(tag) {
        return tag.length > 1 && !tag.includes(',');
      },
    },
    mounted() {
      this.getServiceList();
      this.getContent(this.$route.params.serviceId);
    },
}
</script>
