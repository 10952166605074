<template>
  <div class="h-screen login-bg">
    <!-- Outer Row -->
    <div class="container h-100 d-flex align-items-center justify-content-center">
      <div class="card o-hidden border-0">
        <div class="card-body p-40">
          <h2 class="login-top text-gray-600 mb-4 d-flex align-items-center mb-40">
            <router-link to="/user/login" class="text-gray fs-40"><i class="xi-arrow-left mr-8"></i></router-link>
            <span>비밀번호 찾기</span>
          </h2>
          <form class="user" @submit.prevent="password">
            <div class="form-group mb-40">
              <input type="email" class="form-control" name="username" id="idUsername" required placeholder="회사 이메일" v-model="username">
            </div>
            <button class="btn btn-primary btn-user">임시 비밀번호 발송</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppPassword',
  data() {
    return {
      username: null,
    }
  },
  methods: {
    password(){
      const formData = new FormData();
      formData.append('email', this.username);
      this.$utils.startLoading();
      this.$axios.post('/api/user/password/reset', formData)
      .then(() => {
        this.$root.$emit('showToast', 'success', '임시 비밀번호를 발송하였습니다.');
        this.$router.push({ name: "login" });
        this.$utils.endLoading();
      })
      .catch(() => {
        // console.log(err.response.data);
        this.$root.$emit('showToast', 'danger', '회사 이메일을 확인해주세요.');
        this.$utils.endLoading();
      });
    },
  },
}
</script>