<template>
  <b-modal id="id_img_upload_modal" class="small" size="lg" centered>
    <template #modal-header>
      <h2 class="modal-title">{{ title }}</h2>
    </template>
    <div class="modal-body-inner">
      <div class="img-upload-wrap">
        <div class="img-upload-file">
          <input type="file" name="uploadImg" id="idUploadImg" @drop="dropImg" multiple accept=".jpg,.jpeg,.png,.gif">
          <div class="img-upload-visual">
            <i class="xi-cloud-upload mb-8"></i>
            <p class="mb-24">
              드래그 & 드롭 또는<br>
              파일을 업로드해주세요.
            </p>
            <button for="idUploadImg" class="btn btn-primary small mx-auto">사진 업로드</button>
          </div>
        </div>
        <div class="img-upload-list">
          <div class="img-upload-list-header">
            <p>총 등록된 이미지 <b class="img-count">{{ imgList.length }}</b>건</p>
            <button type="button" class="btn-icon btn-remove-toggle">
              <i class="xi-trash mr-4"></i>
              <span>이미지 정리</span>
            </button>
          </div>
          <div class="img-upload-list-body">
            <div class="row">
              <div class="col-4 col-sm-3" :key="index" v-for="(item, index) in imgList">
                <div class="img-item" :class="{active : mainImgId == item.id,  applied: mainImgId == item.id}" :data-name="item.image.replace('images/', '')">
                  <img :src="`${$axios.defaults.baseURL}/media/${item.image}`" :alt="item.image.substring(0, item.image.lastIndexOf('.')).replace('/media/images/', '')">
                </div>
                <button type="button" class="btn-icon btn-remove-img" @click="removeImgList.push(item.id)"><i class="xi-close"></i></button>
              </div>
              <div class="data-none" v-if="imgList.length == 0">
                <p>등록된 이미지가 없습니다.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #modal-footer>
      <div class="btn-wrap">
        <button type="button" class="btn btn-outline small" @click="$bvModal.hide('id_img_upload_modal')">취소하기</button>
        <button type="button" class="btn btn-primary small" @click="insertImg">적용하기</button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'AppImageUploadModal',
  data() {
    return {
      imgList: {},
      removeImgList: [],
      isChangeMainImg: false,
    };
  },
  props: {
    title: String,
    serviceId: Number,
    mainImgId: Number,
  },
  methods: {
    getImgList(){
      this.$axios.get(`/api/image/${this.$route.params.id}?service=${this.serviceId}&content=${this.$route.params.contentType}`, {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      })
      .then((res) => {
        this.imgList = JSON.parse(res.data.images);
      })
      // .catch((err) => {
      //   console.log(err.response.data);
      // })
    },
    insertImg() {
      const newImgList = document.querySelectorAll('input[name="imgFile"]');
      const mainImg = document.querySelector('.img-item.active:not(.applied)');

      // 이미지 추가
      for(let i = 0; i < newImgList.length; i++) {
        const formData = new FormData();
        formData.append('image', newImgList[i].files[0]);
        formData.append('service', this.serviceId);
        formData.append('source', '미정');
        this.$axios.post(`/api/image/${this.$route.params.id}`, formData, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.getters.token
          }
        })
        .then(() => {
          if(i == newImgList.length - 1) {
            if(mainImg != null) {
              this.$root.$emit('showToast', 'success', '사진이 업로드 되었습니다.');
              this.$bvModal.hide('id_img_upload_modal');
              // 대표이미지 설정
              this.$eventBus.updateImgList(mainImg.dataset.name.replaceAll('/media/', ''));
            } else {
              this.$root.$emit('showToast', 'success', '사진이 업로드 되었습니다.');
              this.$bvModal.hide('id_img_upload_modal');
            }

            // 이미지 삭제
            if(this.removeImgList.length > 0) {
              this.$axios.delete(`/api/image/${this.$route.params.id}?service=${this.serviceId}&delete=${this.removeImgList.toString()}`, {
                headers: {
                  'Authorization': 'Bearer ' + this.$store.getters.token
                }
              })
              .then(() => {
                this.imgList = {};
              })
              // .catch((err) => {
              //   console.log(err.response.data);
              // });
            }
          }
        })
        .catch((err) => {
          // console.log(err.response.data);
          this.$root.$emit('showToast', 'success', err.response.data.image[0]);
        });
      }

      if(newImgList.length == 0) {
        if(mainImg != null) {
          this.$root.$emit('showToast', 'success', '사진이 업로드 되었습니다.');
          this.$bvModal.hide('id_img_upload_modal');
          // 대표이미지 설정
          this.$eventBus.updateImgList(mainImg.dataset.name.replaceAll('/media/', ''));
        } else {
          this.$root.$emit('showToast', 'success', '사진이 업로드 되었습니다.');  
          this.$bvModal.hide('id_img_upload_modal');
        }

        // 이미지 삭제
        if(this.removeImgList.length > 0) {
          this.$axios.delete(`/api/image/${this.$route.params.id}?service=${this.serviceId}&delete=${this.removeImgList.toString()}`, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.getters.token
            }
          })
          .then(() => {
            this.imgList = {};
          })
          // .catch((err) => {
          //   console.log(err.response.data);
          // });
        }
      }
    },
    dropImg(e){
      for(let i = 0; i < e.dataTransfer.files.length; i++) {
        let fileName = e.dataTransfer.files[i].name;
        let lastDot = fileName.lastIndexOf('.');
        let ext = fileName.substring(lastDot + 1, fileName.length).toLowerCase();

        if(ext != 'jpg' && ext != 'jpeg' && ext != 'png' && ext != 'gif') {
          this.$root.$emit('showToast', 'danger', '이미지만 업로드가 가능합니다.');
          e.preventDefault();
          break;
        }
      }
    }
  },
  mounted() {
    this.$eventBus.$on('getImgList' ,() => {
      this.removeImgList = [];
      this.getImgList();
    })
  },
}
</script>