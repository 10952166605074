<template>
  <div class="h-screen login-bg">
    <!-- Outer Row -->
    <div class="container h-100 d-flex align-items-center justify-content-center">
      <div class="card o-hidden border-0">
        <div class="card-body p-40">
          <h2 class="login-top text-gray-600 mb-4 d-flex align-items-center mb-40">
            <span>회원가입</span>
          </h2>
          <form @submit.prevent="signup">
            <div class="form-group">
              <input type="text" class="form-control" name="name" id="idName" required  placeholder="이름" v-model="name" tabindex="1">
            </div>
            <div class="form-group">
              <button type="button" class="text-primary btn-check" @click="checkEmail()" tabindex="3"></button>
              <input type="email" class="form-control" name="username" id="idUsername" required placeholder="회사 이메일" v-model="username" tabindex="2">
            </div>
            <div class="form-group mb-48">
              <input type="password" class="form-control" name="password" id="idPassword" required pattern="^.*(?=^.{8,}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[`~!@#$%^&\*\-_+=<>?{}\[\]\/\|\\]).*$" placeholder="패스워드" v-model="password" tabindex="4">
              <div class="feedback">문자, 숫자, 기호를 포함하여 8자 이상을 사용해주세요.</div>
            </div>
            <button type="submit" class="btn btn-primary btn-user" tabindex="5">회원가입</button>
          </form>
        </div>
        <div class="card-footer px-40 py-28">
          <div class="d-flex align-items-center">
            <p>아직 회원이 아니신가요?</p>
            <router-link to="/user/login" class="btn-link ml-16 font-weight-bold" tabindex="6">로그인 하기</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppSignup',
  data() {
    return {
      name: null,
      username: null,
      password: null,
      group: null,
      isCheckedEmail: false,
    }
  },
  methods: {
    signup(){
      if(this.isCheckedEmail) {
        const formData = new FormData();
        formData.append('name', this.name);
        formData.append('username', this.username);
        formData.append('email', this.username);
        formData.append('password', this.password);

        this.$axios.post('/api/user', formData)
        .then(() => {
          this.$root.$emit('showToast', 'success', '회원가입을 완료하였습니다.');
          this.$router.replace({name: 'login'});
        })
        // .catch(() => {
        //   console.log(err.response.data);
        // });
      } else {
        this.$root.$emit('showToast', 'danger', '회사 이메일 중복확인을 해주세요.');
        document.querySelector('#idUsername').classList.add('is-invalid');
        document.querySelector('#idUsername').focus();
      }
    },
    checkEmail(){
      if(!document.querySelector('#idUsername').validity.valid) {
        this.$root.$emit('showToast', 'danger', '이메일 주소를 확인해주세요.');
        document.querySelector('#idUsername').classList.add('is-invalid');
        document.querySelector('#idUsername').focus();
      } else {
        const formData = new FormData();
        formData.append('email', this.username);

        this.$axios.get(`/api/user?email=${this.username}`)
        .then(() => {
          this.isCheckedEmail = true;
          document.querySelector('.btn-check').classList.add('active');
          document.querySelector('#idUsername').classList.remove('is-invalid');
          document.querySelector('#idUsername').classList.add('is-valid');
          document.querySelector('#idUsername').disabled = true;
        })
        .catch(() => {
          // console.log(err.response.data);
          this.$root.$emit('showToast', 'danger', '이미 존재하는 회사 이메일입니다.');
          document.querySelector('#idUsername').classList.add('is-invalid');
          document.querySelector('#idUsername').focus();
        });
      }
    }
  },
  mounted() {
    this.$utils.setValidation();
  },
}
</script>