<template>
  <!-- Topbar -->
    <nav class="navbar navbar-expand topbar static-top">

        <!-- Sidebar Toggle (Topbar) -->
        <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle">
            <i class="xi-bars"></i>
        </button>

        <!-- Topbar Search -->
        <div class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search" v-if="this.$route.name == 'list'">
            <div class="input-group">
                <input type="text" class="form-control bg-light border-0 small" placeholder="검색어를 입력해주세요." aria-label="Search" aria-describedby="basic-addon2" v-model="searchKeyword" v-on:keyup.enter="search">
                <div class="input-group-append">
                    <button class="btn btn-primary" type="button" @click="search">
                        <i class="xi-search"></i>
                    </button>
                </div>
            </div>
        </div>

        <!-- Topbar Navbar -->
        <ul class="navbar-nav ml-auto">
 
            <!-- Nav Item - Search Dropdown (Visible Only XS) -->
            <li class="nav-item dropdown no-arrow d-sm-none" v-if="this.$route.name == 'list'">
                <a class="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="xi-search"></i>
                </a>
                <!-- Dropdown - Messages -->
                <div class="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                    aria-labelledby="searchDropdown">
                    <div class="form-inline mr-auto w-100 navbar-search">
                        <div class="input-group">
                            <input type="text" class="form-control bg-light border-0 small" placeholder="검색어를 입력해주세요." aria-label="Search" aria-describedby="basic-addon2" v-model="searchKeyword">
                            <div class="input-group-append">
                                <button class="btn btn-primary" type="button" @click="search" v-on:keyup.enter="search">
                                    <i class="xi-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </li>

            <!-- Nav Item - Alerts -->
            <!-- <li class="nav-item dropdown no-arrow mx-1">
                <a class="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="xi-bell"></i>
                    <span class="badge badge-danger badge-counter">3+</span>
                </a>
                <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                    aria-labelledby="alertsDropdown">
                    <h5 class="dropdown-header">
                        알림
                    </h5>
                    <a class="dropdown-item d-flex align-items-center" href="#">
                        <div class="mr-3">
                            <div class="icon-circle bg-primary">
                                <i class="xi-mail"></i>
                            </div>
                        </div>
                        <div>
                            <div class="small text-gray-500">2022년 4월 18일</div>
                            <span class="font-weight-bold">Culpa reprehenderit officia labore aliqua dolor incididunt et veniam culpa Lorem amet commodo.</span>
                        </div>
                    </a>
                    <a class="dropdown-item d-flex align-items-center" href="#">
                        <div class="mr-3">
                            <div class="icon-circle bg-success">
                                <i class="xi-mail"></i>
                            </div>
                        </div>
                        <div>
                            <div class="small text-gray-500">2022년 4월 18일</div>
                            Dolore fugiat sit aute ad exercitation quis nisi incididunt culpa id deserunt dolore.
                        </div>
                    </a>
                    <a class="dropdown-item d-flex align-items-center" href="#">
                        <div class="mr-3">
                            <div class="icon-circle bg-warning">
                                <i class="xi-mail"></i>
                            </div>
                        </div>
                        <div>
                            <div class="small text-gray-500">2022년 4월 18일</div>
                            Eu est aute elit nulla laborum veniam ullamco officia sunt fugiat velit eiusmod reprehenderit.
                        </div>
                    </a>
                    <a class="dropdown-item text-center small text-gray-500" href="#">Show All Alerts</a>
                </div>
            </li> -->

            <!-- <div class="topbar-divider d-none d-sm-block"></div> -->

            <!-- Nav Item - User Information -->
            <!-- <li class="nav-item dropdown">
                <a href="javascript:void(0)" class="nav-link dropdown-toggle" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="xi-user mr-6"></i>
                    <span class="d-none d-lg-inline text-white small">{{ this.$store.getters.userInfo.name }}</span>
                </a>
                Dropdown - User Information
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                    <div class="dropdown-divider"></div>
                    <router-link to="/mypage" class="dropdown-item">내정보</router-link>
                    <a href="javascript:void(0)" class="dropdown-item" @click="logout">로그아웃</a>
                </div>
            </li> -->
            <b-dropdown>
                <template #button-content>
                    <i class="xi-user mr-8"></i>
                    <span class="d-none d-lg-inline mr-4">{{ $store.getters.userInfo.name }}</span>
                </template>
                <b-dropdown-item to="/mypage">내정보</b-dropdown-item>
                <b-dropdown-item @click="logout">로그아웃</b-dropdown-item>
            </b-dropdown>
        </ul>

    </nav>
    <!-- End of Topbar -->
</template>

<script>
export default {
  name: 'AppHeader',
  data() {
    return {
      searchType: 'title',
      searchKeyword: ''
    }
  },
  methods: {
    logout(){
        this.$bvToast.hide();
        this.$store.commit('deleteToken');
        this.$router.replace({name: 'login'});
        this.$root.$emit('showToast', 'success', '로그아웃하였습니다.');
    },
  },
}
</script>
