<template>
  <b-modal id="idServiceModal" size="lg" hide-header hide-footer centered>
    <form @submit.prevent="insertService">
      <div class="modal-header">
        <h2 class="modal-title">{{ title }}</h2>
      </div>
      <div class="modal-body-inner">
        <div class="form-group mb-24">
          <label for="idProjectName">프로젝트 명<sup>*</sup></label>
          <input type="text" name="projectName" id="idProjectName" class="form-control" placeholder="프로젝트명을 입력해주세요." required v-model="name">
        </div>
        <div class="form-group mb-24">
          <label for="idCloneData">클론할 데이터<sup>*</sup></label>
          <select name="cloneData" id="idCloneData" class="form-control" required v-model="copyTarget">
            <option :value="item.id" :key="index" v-for="(item, index) in serviceList" selected>{{ item.name == 'original' ? '원본 데이터' : item.name }}</option>
          </select>
          <p class="input-help">*선택하신 데이터를 기반으로 프로젝트가 생성됩니다.</p>
        </div>
        <div class="form-group mb-0">
          <label for="idProjectDesc">설명</label>
          <textarea name="projectDesc" id="idProjectDesc" class="form-control" placeholder="프로젝트에 대한 간력한 설명을 입력해주세요." v-model="description"></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <div class="btn-wrap">
          <button type="button" class="btn btn-outline small" @click="$bvModal.hide('idServiceModal')">취소하기</button>
          <button class="btn btn-primary small">추가하기</button>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
export default {
  name: 'AppServiceModal',
  data() {
    return {
      serviceList: [],
      name: null,
      description: null,
      copyTarget: 0,
    }
  },
  props: {
    title: String,
  },
  methods: {
    getServiceList() {
      this.$axios.get('/api/service', {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      })
      .then((res) => {
        this.serviceList = res.data.service;
        if(this.serviceList.length > 0) {
          this.copyTarget = res.data.service[0].id;
        }
      })
      // .catch(() => {
      //   console.log(err.response.data);
      // });
    },
    insertService() {
      this.$utils.startLoading();

      const formData = new FormData();
      formData.append('name', this.name);
      formData.append('description', this.description);
      formData.append('copy_target', this.copyTarget);

      this.$axios.post('/api/service', formData, {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      })
      .then((res) => {
        this.serviceList = res.data.service;
        this.$root.$emit('showToast', 'success', '서비스가 추가되었습니다.');
        this.$bvModal.hide('idServiceModal');
        this.$eventBus.updateList();
        this.$utils.endLoading();
      })
      .catch(() => {
        // console.log(err.response.data);
        this.$root.$emit('showToast', 'danger', '이미 존재하는 서비스입니다.');
        this.$utils.endLoading();
      });
    },
  },
  mounted() {
    this.$eventBus.$on('setValidation' ,() => {
      setTimeout(() => {
        this.$utils.setValidation();
      }, 300);
    })
    this.getServiceList();
  }
}
</script>