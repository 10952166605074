<template>
  <div class="container-fluid p-0">
    <div class="detail-wrap collapsed">
      <div class="detail-new-wrap w-100">
        <form @submit.prevent="createBanner">
          <input type="text" name="idTitle" id="idTitle" class="form-control detail-title mb-12" required placeholder="배너 제목을 입력해주세요." v-model="banner.title" />
          <div class="d-flex flex-wrap align-itens-center justify-content-between mb-36">
            <div class="blank"></div>
          </div>
          <div class="img-wrap mb-42" :style="`background: url(${imgThumbnail}) no-repeat center / cover;`" v-if="imgThumbnail != null">
            <div class="img-info pr-56">
              <p class="img-name">{{ imgName }}</p>
              <span class="img-ext">{{ imgExt }}</span>
              <label for="idUploadImg" class="btn-upload-img"><i class="xi-cloud-upload"></i></label>
              <input type="file" name="imgFile" id="idUploadImg" accept=".jpg,.jpeg,.png,.gif" @change="changeImg" hidden>
            </div>
          </div>
          <div class="img-wrap mb-42" v-else>
            <div class="img-info pr-56">
              <p class="img-name">{{ imgName }}</p>
              <span class="img-ext">{{ imgExt }}</span>
              <label for="idUploadImg" class="btn-upload-img"><i class="xi-cloud-upload"></i></label>
              <input type="file" name="imgFile" id="idUploadImg" accept=".jpg,.jpeg,.png,.gif,.svg" @change="changeImg" hidden>
            </div>
          </div>
          <div class="form-group mb-32">
            <label for="idUrl">URL<sup>*</sup></label>
            <input type="text" name="idUrl" id="idUrl" class="form-control" required placeholder="배너 연결 링크를 입력해주세요." v-model="banner.url">
          </div>
          <!-- <div class="form-group mb-32">
            <label for="idOrdering">순서<sup>*</sup></label>
            <input type="number" name="idOrdering" id="idOrdering" class="form-control" min="0" max="2147483647" required placeholder="배너 순서를 입력해주세요." v-model="banner.ordering">
          </div> -->
          <div class="btn-wrap">
            <button type="button" class="btn btn-outline small" @click="$router.push({name: 'bannerList'})">목록으로</button>
            <button class="btn btn-primary small">저장</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "AppCreate",
    data() {
      return {
        banner: {
          title: '',
          url: '',
          ordering: 0,
        },
        imgThumbnail: null,
        imgName: null,
        imgExt: null,
      };
    },
    methods: {
      changeImg(event){
        let input = event.target;
        if (input.files && input.files[0]) {
          this.imgName = input.files[0].name.substring(0, input.files[0].name.lastIndexOf('.'));
          this.imgExt = input.files[0].name.substring(input.files[0].name.lastIndexOf('.'));
          var reader = new FileReader();
          reader.onload = (e) => {
            this.imgThumbnail = e.target.result;
          }
          reader.readAsDataURL(input.files[0]);
        }
      },
      createBanner() {
        let imgInput = document.querySelector('#idUploadImg');
        const formData = new FormData();
        formData.append('title', this.banner.title);
        formData.append('service', this.$route.params.serviceId);
        formData.append('is_active', true);
        formData.append('is_url', true);
        if(imgInput.files && imgInput.files[0]) {
          formData.append('image', imgInput.files[0]);
        } else {
          this.$root.$emit('showToast', 'danger', '배너 이미지를 추가해주세요.');
          return;
        }
        formData.append('url', this.banner.url);
        formData.append('ordering', 0);

        this.$axios.post('/api/banner', formData, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.getters.token
          }
        })
        .then(() => {
          this.$root.$emit('showToast', 'success', '배너가 저장되었습니다.');
        })
        // .catch((err) => {
        //   console.log(err.response.data);
        // })
      },
    },
    mounted() {
      setTimeout(() => {
        this.$utils.setValidation();
      },300);
    },
}
</script>