<template>
  <!-- Sidebar -->
  <ul class="navbar-nav sidebar accordion" id="accordionSidebar">
    <!-- Sidebar - Brand -->
    <router-link class="sidebar-brand d-flex align-items-center" to="/">
        <h1 class="sidebar-brand-text text-white">D.iF CMS</h1>
    </router-link>

    <!-- Nav Item - Dashboard -->
    <li class="nav-item">
      <router-link class="nav-link text-gray-600 font-weight-bold" to="/">
        <i class="xi-dashboard"></i>
        <span>대시보드</span>
        <!-- <sup class="new"></sup> -->
      </router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link text-gray-600 font-weight-bold" to="/content/list" :class="{'router-link-exact-active': $route.path.includes('content')}">
        <i class="xi-apps"></i>
        <span>식품/음식 콘텐츠</span>
      </router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link text-gray-600 font-weight-bold" to="/nutrient/list" :class="{'router-link-exact-active': $route.path.includes('nutrient')}">
        <i class="xi-apps"></i>
        <span>영양성분</span>
      </router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link text-gray-600 font-weight-bold" to="/banner/list" :class="{'router-link-exact-active': $route.path.includes('banner')}">
        <i class="xi-apps"></i>
        <span>배너관리</span>
      </router-link>
    </li>

    <!-- Nav Item - Pages Collapse Menu -->
    <!-- <li class="nav-item">
      <a class="nav-link" href="#" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
        <i class="fas fa-fw fa-cog"></i>
        <span>Components</span>
      </a>
      <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Custom Components:</h6>
          <a class="collapse-item" href="buttons.html">Buttons</a>
          <a class="collapse-item" href="cards.html">Cards</a>
        </div>
      </div>
    </li> -->

    <!-- Sidebar Toggler (Sidebar) -->
    <div class="text-center d-none d-md-inline">
        <button class="rounded-circle border-0" id="sidebarToggle"></button>
    </div>
  </ul>
  <!-- End of Sidebar -->
</template>

<script>
export default {
  name: 'AppSidebar',
}
</script>