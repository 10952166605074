<template>
  <nav>
    <b-pagination class="justify-content-center mt-32" v-model="currentPage" :total-rows="this.total" :per-page="perPage" @page-click="sendParent">
      <template #first-text><i class="xi-step-backward"><span class="sr-only">첫번째 페이지</span></i></template>
      <template #prev-text><i class="xi-play rotate-180"><span class="sr-only">이전 페이지</span></i></template>
      <template #next-text><i class="xi-play"><span class="sr-only">다음 페이지</span></i></template>
      <template #last-text><i class="xi-step-forward"><span class="sr-only">마지막 페이지</span></i></template>
    </b-pagination>
  </nav>
</template>

<script>
export default {
  name: 'AppPagination',
  data(){
    return {
      currentPage: 1,
      perPage: 0,
    }
  },
  props: {
    type: String,
    total: Number,
  },
  methods: {
    sendParent(el, page){
      this.$emit('sendParent', page, this.perPage);
    }
  },
  mounted(){
    switch(this.type) {
      case 'nutrient':
        this.perPage = 14;
        break;
      case 'content':
        this.perPage = 9;
        break;
      case 'banner':
        this.perPage = 8;
        break;
      default:
        this.perPage = 9;
        break;
    }
    this.$eventBus.$on('updateCurrentPage' ,(data) => {
      this.currentPage = data;
    });
  },
}
</script>