<template>
  <div class="h-screen login-bg">
    <!-- Outer Row -->
    <div class="container h-100 d-flex align-items-center justify-content-center">
      <div class="card o-hidden border-0">
        <div class="card-body p-40">
          <h2 class="login-top text-gray-600 mb-4 d-flex align-items-center mb-40">
            <span>로그인</span>
          </h2>
          <form @submit.prevent="login">
            <div class="form-group">
              <input type="email" class="form-control" name="username" id="idUsername" required placeholder="이메일" v-model="username">
            </div>
            <div class="form-group">
              <input type="password" class="form-control" name="password" id="idPassword" placeholder="패스워드" required v-model="password">
            </div>
            <div class="form-group text-right mb-8">
              <router-link to="/user/password" class="btn-link text-dark">비밀번호 찾기</router-link>
            </div>
            <button class="btn btn-primary btn-user">로그인</button>
          </form>
        </div>
        <div class="card-footer px-40 py-28">
          <div class="d-flex align-items-center">
            <p>아직 회원이 아니신가요?</p>
            <router-link to="/user/signup" class="btn-link ml-16 font-weight-bold">회원가입 하기</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppLogin',
  data() {
    return {
      name: null,
      username: null,
      password: null,
    }
  },
  methods: {
    login(){
      const formData = new FormData();
      formData.append('username', this.username);
      formData.append('password', this.password);

      this.$axios.post('/api/token', formData)
      .then((resToken) => {
        this.$store.commit('insertToken', [resToken.data.access, resToken.data.refresh]);

        this.$axios.get('/api/user/info', {
          headers: {
            'Authorization': 'Bearer ' + resToken.data.access
          }
        })
        .then((res) => {
          if(res.data.data[0].group_name != 'normal' && res.data.data[0].group_name != 'design' &&
             res.data.data[0].group_name != 'marketing' && res.data.data[0].group_name != 'dif' &&
             res.data.data[0].group_name != 'master') {
            res.data.data[0].group_name = 'normal';
          }
          delete res.data.data[0]['password'];
          this.$store.commit('setUserInfo', res.data.data[0]);

          this.$bvToast.hide();

          if(this.$store.getters.userInfo.is_password_reset) {
            if(!this.$route.path.includes('mypage')) {
              this.$root.$emit('showToast', 'warning', '비밀번호를 변경해주세요.');
              this.$router.replace({name: 'mypage'});
            }
          } else {
            this.$router.replace({name: 'index'});
          }
        })
        // .catch((err) => {
        //   console.log(err.response.data);
        // });
      })
      .catch(() => {
        // console.log(err.response.data);
        this.$root.$emit('showToast', 'danger', '이메일과 패스워드를 확인해주세요.');
      });
    },
  },
}
</script>