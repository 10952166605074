<template>
  <!-- Begin Page Content -->
  <div class="container-fluid">

    <!-- Page Heading -->
    <div class="d-flex align-items-center justify-content-between mb-32">
      <h2 class="text-gray-600">대시보드</h2>
    </div>
    <div class="list-wrap" v-if="$store.getters.userInfo.group_name != 'normal'">
      <div class="list-container" v-if="dashboard != null && serviceList.length != 0">
        <div class="row mb-16">
          <div class="col-12 col-md-6 col-lg-4 col-xxl-2">
            <b-form-select name="service" id="idService" class="form-control custom-select-tab mr-16" v-model="selectedServiceId" @change="getDashboard">
              <b-form-select-option :value="item.id" :key="index" v-for="(item, index) in serviceList">{{ item.name }}</b-form-select-option>
            </b-form-select>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4 col-xxl-2 mb-32">
            <div class="card">
              <div class="card-body">
                <p class="p1 mb-10">전체 식품</p>
                <div class="d-flex align-items-center justify-content-between">
                  <span class="card-count"><b class="mr-4">{{ this.dashboard.filter.food }}</b>건</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 col-xxl-2 mb-32">
            <div class="card">
              <div class="card-body">
                <p class="p1 mb-10">전체 음식</p>
                <div class="d-flex align-items-center justify-content-between">
                  <span class="card-count"><b class="mr-4">{{ this.dashboard.filter.dish }}</b>건</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 col-xxl-2 mb-32">
            <div class="card">
              <div class="card-body">
                <p class="p1 mb-10">전체 영양성분</p>
                <div class="d-flex align-items-center justify-content-between">
                  <span class="card-count"><b class="mr-4">{{ this.dashboard.filter.nutrient }}</b>건</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 col-xxl-2 mb-32">
            <div class="card">
              <div class="card-body">
                <p class="p1 mb-10" v-if="this.$store.getters.userInfo.group_name == 'design' || this.$store.getters.userInfo.group_name == 'marketing'">검토완료/반려 식품</p>
                <p class="p1 mb-10" v-if="this.$store.getters.userInfo.group_name == 'dif'">검토요청 식품</p>
                <p class="p1 mb-10" v-if="this.$store.getters.userInfo.group_name == 'master'">반영요청 식품</p>
                <div class="d-flex align-items-center justify-content-between">
                  <span class="card-count"><b class="mr-4">{{ this.dashboard.filter.check_food }}</b>건</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 col-xxl-2 mb-32">
            <div class="card">
              <div class="card-body">
                <p class="p1 mb-10" v-if="this.$store.getters.userInfo.group_name == 'design' || this.$store.getters.userInfo.group_name == 'marketing'">검토완료/반려 음식</p>
                <p class="p1 mb-10" v-if="this.$store.getters.userInfo.group_name == 'dif'">검토요청 음식</p>
                <p class="p1 mb-10" v-if="this.$store.getters.userInfo.group_name == 'master'">반영요청 음식</p>
                <div class="d-flex align-items-center justify-content-between">
                  <span class="card-count"><b class="mr-4">{{ this.dashboard.filter.check_dish }}</b>건</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 col-xxl-2 mb-32">
            <div class="card">
              <div class="card-body">
                <p class="p1 mb-10" v-if="this.$store.getters.userInfo.group_name == 'design' || this.$store.getters.userInfo.group_name == 'marketing'">검토완료/반려 영양성분</p>
                <p class="p1 mb-10" v-if="this.$store.getters.userInfo.group_name == 'dif'">검토요청 영양성분</p>
                <p class="p1 mb-10" v-if="this.$store.getters.userInfo.group_name == 'master'">반영요청 영양성분</p>
                <div class="d-flex align-items-center justify-content-between">
                  <span class="card-count"><b class="mr-4">{{ this.dashboard.filter.check_nutrient }}</b>건</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card dashboard">
          <div class="card-header">
            <div class="card-title">
              <div class="d-flex align-items-center">
                <p class="text-gray-500 font-weight-bold fs-18">
                  {{ serviceNameObj[selectedServiceId] }}의
                  <span v-if="this.$store.getters.userInfo.group_name == 'design' || this.$store.getters.userInfo.group_name == 'marketing'">검토완료/반려 콘텐츠</span>
                  <span v-if="this.$store.getters.userInfo.group_name == 'dif'">검토요청 콘텐츠</span>
                  <span v-if="this.$store.getters.userInfo.group_name == 'master'">반영요청 콘텐츠</span>
                </p>
                <span class="badge badge-primary rounded-8 ml-8">{{ dashboard.content.length }}</span>
              </div>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="table-wrap">
              <table class="table table-dashboard">
                <caption class="sr-only" v-if="this.$store.getters.userInfo.group_name == 'design' || this.$store.getters.userInfo.group_name == 'marketing'">검토완료/반려 콘텐츠</caption>
                <caption class="sr-only" v-if="this.$store.getters.userInfo.group_name == 'dif'">검토요청 콘텐츠</caption>
                <caption class="sr-only" v-if="this.$store.getters.userInfo.group_name == 'master'">반영요청 콘텐츠</caption>
                <colgroup>
                  <col>
                  <col style="width: 140px;">
                  <col style="width: 140px;">
                  <col style="width: 140px;">
                  <col style="width: 140px;">
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      <div class="d-inline-flex align-items-center">
                        타이틀
                        <b-dropdown left class="dropdown-table-filter no-arrow ml-8">
                          <template #button-content>
                              <i class="xi-filter"></i>
                          </template>
                          <div href="javascript:void(0)" class="dropdown-item" onclick="event.stopPropagation();">
                            <div class="checkbox-wrap">
                              <input type="checkbox" name="all" id="idAll" class="sr-only all" :checked="(selectedContentType['dish'] && selectedContentType['food'] && selectedContentType['nutrient'])"/>
                              <label for="idAll">전체 항목</label>
                            </div>
                          </div>
                          <div href="javascript:void(0)" class="dropdown-item" onclick="event.stopPropagation();">
                            <div class="checkbox-wrap">
                              <input type="checkbox" name="dish" id="idDish" class="sr-only each" v-model="selectedContentType['dish']" @change="getDashboard()" />
                              <label for="idDish">음식</label>
                            </div>
                          </div>
                          <div href="javascript:void(0)" class="dropdown-item" onclick="event.stopPropagation();">
                            <div class="checkbox-wrap">
                              <input type="checkbox" name="food" id="idFood" class="sr-only each" v-model="selectedContentType['food']" @change="getDashboard()" />
                              <label for="idFood">식품</label>
                            </div>
                          </div>
                          <div href="javascript:void(0)" class="dropdown-item" onclick="event.stopPropagation();">
                            <div class="checkbox-wrap">
                              <input type="checkbox" name="nutrient" id="idNutrient" class="sr-only each" v-model="selectedContentType['nutrient']" @change="getDashboard()" />
                              <label for="idNutrient">영양성분</label>
                            </div>
                          </div>
                        </b-dropdown>
                      </div>
                    </th>
                    <th scope="col" class="text-center">진행상황</th>
                    <th scope="col" class="text-center">수정자</th>
                    <th scope="col" class="text-center">검토자</th>
                    <th scope="col" class="text-center">검토일</th>
                  </tr>
                </thead>
                <tbody>
                  <tr :key="index" v-for="(item, index) in [...dashboard.content.food, ...dashboard.content.dish, ...dashboard.content.nutrient]" @click="goDetail(item.contentType, item.content_id)">
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="img-wrap mr-24" v-if="item.content_type == 'dish' || item.content_type == 'food'">
                          <img v-if="item.image != null" :src="`${$axios.defaults.baseURL}/media/${item.image}`" :alt="item.name" />
                        </div>
                        <div class="img-wrap img-nutrient mr-24" v-else>
                          <img src="@/assets/images/ic-nutrient.png" :alt="item.name" />
                        </div>
                        <span class="badge rounded-8 mr-8" v-if="item.content_type == 'food'">식품</span>
                        <span class="badge rounded-8 mr-8" v-if="item.content_type == 'dish'">음식</span>
                        <span class="badge rounded-8 mr-8" v-if="item.content_type == 'nutrient'">영양성분</span>
                        <p class="p1">{{ item.name }}</p>
                      </div>
                    </td>
                    <td class="text-center" v-if="item.review_status == 'refuse'">반려</td>
                    <td class="text-center" v-if="item.review_status == 'approval'">검토완료</td>
                    <td class="text-center" v-if="item.review_status == 'reassessment'">재검토요청</td>
                    <td class="text-center" v-if="item.review_status == 'assessment'">검토요청</td>
                    <td class="text-center" v-if="item.review_status == 'request_reflection'">반영요청</td>
                    <td class="text-center">{{ item.requester }}</td>
                    <td class="text-center">{{ item.reviewer }}</td>
                    <td class="text-center">{{ $utils.dateFormatter(item.review_time).dash }}</td>
                  </tr>
                  <tr class="data-none" v-if="dashboard != null && [...dashboard.content.food, ...dashboard.content.dish, ...dashboard.content.nutrient].length == 0">
                    <td colspan="5">콘텐츠 내역이 없습니다.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="data-none" v-if="dashboard != null && Object.keys(dashboard).length == 0">
        <p>등록된 서비스가 없습니다.</p>
      </div>
    </div>
    <div class="list-wrap" v-else>
      <div class="data-none">
        <p>권한이 없습니다.</p>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</template>

<script>
export default {
  name: 'AppIndex',
  data() {
    return {
      dashboard: null,
      serviceNameObj: {},
      selectedServiceId: 1,
      selectedContentType: {
        food: true,
        dish: true,
        nutrient: true,
      },
    }
  },
  methods: {
    goDetail(contentType, id) {
      if(contentType == 'nutrient') {
        this.$router.push({ name: 'nutrientDetail', params: {serviceId: this.selectedServiceId, id} });
      } else {
        this.$router.push({ name: 'contentDetail', params: {serviceId: this.selectedServiceId, id, contentType } });
      }
    },
    getDashboardFirst() {
      if(this.$store.getters.userInfo.group_name != 'normal') {
        this.$axios.get('/api/service', {
          headers: {
            'Authorization': 'Bearer ' + this.$store.getters.token
          }
        })
        .then((res) => {
          if(res.data.service != null && res.data.service.length > 0) {
            // this.selectedServiceId = res.data.service[0].id;
            this.getDashboard();
          } else {
            this.dashboard = {};
          }
        })
        // .catch((err) => {
        //   console.log(err.response.data);
        // })
      }
    },
    getDashboard() {
      this.$utils.startLoading('table');

      this.$axios.get('/api/service', {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      })
      .then((res) => {
        this.serviceList = res.data.service;
        for (let i = 0; i < this.serviceList.length; i++) {
          this.serviceNameObj[this.serviceList[i].id] = this.serviceList[i].name;
        }

        let url = `/api/dashboard?service=${this.selectedServiceId}`;

        let selectedContentType = [];
        for(let key in this.selectedContentType) {
          if(this.selectedContentType[key]) {
            selectedContentType.push(key);
          }
        }
        
        if(selectedContentType.toString() != '') {
          url += `&content=${selectedContentType.toString()}`;
        } else {
          url += '&content=food,dish,nutrient';
        }
        
        this.$axios.get(url, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.getters.token
          }
        })
        .then((res) => {
          for(let key in res.data.content) {
            if(JSON.parse(res.data.content[key]) == null) {
              res.data.content[key] = [];
            } else {
              res.data.content[key] = JSON.parse(res.data.content[key]);

              for(let i = 0; i < res.data.content[key].length; i++) {
                res.data.content[key][i].contentType = key;
              }
            }
          }
          this.dashboard = res.data;

          this.$utils.endLoading('table');
        })
        .catch(() => {
          // console.log(err.response.data);
          this.$utils.endLoading('table');
        });
      })
      // .catch((err) => {
      //   console.log(err.response.data);
      // })
    },
    setPageInfo(){
      let pageInfo = this.$store.getters.pageInfo;
      if(this.$route.name.includes(pageInfo.name)) {
        this.selectedServiceId = pageInfo.selectedServiceId,
        this.selectedContentType = pageInfo.selectedContentType;
      }
    }
  },
  mounted(){
    this.setPageInfo();
    this.getDashboardFirst();
  },
  updated() {
    this.$store.commit('setPageInfo', {
      name: 'index',
      selectedServiceId: this.selectedServiceId,
      selectedContentType: this.selectedContentType,
    });
  },
}
</script>
