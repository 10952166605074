<template>
  <div class="container-fluid p-0">
    <div class="detail-wrap collapsed" v-if="banner != null">
      <div class="detail-new-wrap w-100">
        <form @submit.prevent="updateBanner">
          <input type="text" name="idTitle" id="idTitle" class="form-control detail-title mb-12" required placeholder="배너 제목을 입력해주세요." :disabled="$store.getters.userInfo.group_name == 'normal'" v-model="banner.title" />
          <div class="d-flex flex-wrap align-itens-center justify-content-between mb-36">
            <div class="blank"></div>
            <div class="d-flex align-items-center" v-if="this.banner.updated_at != null">
              <i class="xi-time fs-18 mr-4"></i>
              <p class="p3 font-weight-normal">{{ this.banner.updated_at }}에 수정</p>
            </div>
          </div>
          <div class="img-wrap mb-42" :style="`background: url(${imgThumbnail}) no-repeat center / cover;`">
            <div class="img-info pr-56">
              <p class="img-name">{{ imgName }}</p>
              <span class="img-ext">{{ imgExt }}</span>
              <label for="idUploadImg" class="btn-upload-img" v-if="$store.getters.userInfo.group_name != 'normal'"><i class="xi-cloud-upload"></i></label>
              <input type="file" name="imgFile" id="idUploadImg" accept=".jpg,.jpeg,.png,.gif,.svg" v-if="$store.getters.userInfo.group_name != 'normal'" @change="changeImg" hidden>
            </div>
          </div>
          <div class="form-group mb-32">
            <label for="idUrl">URL<sup>*</sup></label>
            <input type="text" name="idUrl" id="idUrl" class="form-control" required placeholder="배너 연결 링크를 입력해주세요." :disabled="$store.getters.userInfo.group_name == 'normal'" v-model="banner.url">
          </div>
          <!-- <div class="form-group mb-32">
            <label for="idOrdering">순서<sup>*</sup></label>
            <input type="number" name="idOrdering" id="idOrdering" class="form-control" min="0" max="2147483647" required placeholder="배너 순서를 입력해주세요." :disabled="$store.getters.userInfo.group_name == 'normal'" v-model="banner.ordering">
          </div> -->
          <div class="btn-wrap">
            <button type="button" class="btn btn-outline small" @click="$router.push({name: 'bannerList'})">목록으로</button>
            <button class="btn btn-primary small" v-if="$store.getters.userInfo.group_name != 'normal'">수정</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "AppDetail",
    data() {
      return {
        banner: null,
        imgThumbnail: null,
        imgName: null,
        imgExt: null,
      };
    },
    methods: {
      getBanner() {
        if(this.$route.params.id) {
          // this.$utils.startLoading('detail');
  
          this.$axios.get(`/api/banner/${this.$route.params.id}?service=${this.$route.params.serviceId}`, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.getters.token
            }
          })
          .then((res) => {
            this.banner = res.data[0];
            this.banner.updated_at = this.$utils.dateFormatter(this.banner.updated_at).word;
            this.imgThumbnail = this.$axios.defaults.baseURL + '/media/' + this.banner.image;
            this.imgName = this.banner.image.substring(0, this.banner.image.lastIndexOf('.')).replace('images/', '');
            this.imgExt = this.banner.image.substring(this.banner.image.lastIndexOf('.')).replace('images/', '');
            // this.$utils.endLoading('detail');
          })
          // .catch(() => {
          //   console.log(err.response.data);
          //   this.$utils.endLoading('detail');
          // })
        }
      },
      changeImg(event){
        let input = event.target;
        if (input.files && input.files[0]) {
          this.imgName = input.files[0].name.substring(0, input.files[0].name.lastIndexOf('.'));
          this.imgExt = input.files[0].name.substring(input.files[0].name.lastIndexOf('.'));
          var reader = new FileReader();
          reader.onload = (e) => {
            this.imgThumbnail = e.target.result;
          }
          reader.readAsDataURL(input.files[0]);
        }
      },
      updateBanner() {
        let imgInput = document.querySelector('#idUploadImg');
        const formData = new FormData();
        formData.append('title', this.banner.title);
        formData.append('description', this.banner.description);
        formData.append('service', this.banner.service);
        formData.append('is_active', true);
        formData.append('is_url', true);
        if(imgInput.files && imgInput.files[0]) {
          formData.append('image', imgInput.files[0]);
        }
        formData.append('url', this.banner.url);
        formData.append('ordering', 0);

        this.$axios.post(`/api/banner/${this.banner.id}`, formData, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.getters.token
          }
        })
        .then(() => {
          this.$root.$emit('showToast', 'success', '배너가 저장되었습니다.');
        })
        // .catch((err) => {
        //   console.log(err.response.data);
        // })
      },
    },
    mounted() {
      this.getBanner();
      setTimeout(() => {
        this.$utils.setValidation();
      },300);
    },
}
</script>
