import $ from 'jquery';

window.addEventListener('load', () => {
  Utils.init();
});
// window.addEventListener('DOMContentLoaded', () => {

// });
/**
 * 공통함수
 */
const Utils = {
  /**
   * Set default event
   */
  init: () => {
    $(window).on('resize', function () {
      Utils.setLayout();
    });

    Utils.setLayout();
    Utils.setTableCheckbox();
    Utils.setFilterCheckbox();
    Utils.setImgUpload();
    // Utils.setTabEvent();
    // Utils.setValidation();
    // Utils.setCustomSelectTab();

    // Toggle the side navigation
    $(document).on('click', '#sidebarToggle, #sidebarToggleTop', function () {
      $('body').toggleClass('sidebar-toggled');
      $('.sidebar').toggleClass('toggled');
      if ($('.sidebar').hasClass('toggled')) {
        $('.sidebar .collapse').collapse('hide');
      }
    });

    // Prevent the content wrapper from scrolling when the fixed side navigation hovered over
    $('body.fixed-nav .sidebar').on('mousewheel DOMMouseScroll wheel', function (e) {
      if ($(window).width() > 768) {
        var e0 = e.originalEvent,
          delta = e0.wheelDelta || -e0.detail;
        this.scrollTop += (delta < 0 ? 1 : -1) * 30;
        e.preventDefault();
      }
    });

    // Scroll to top button appear
    $(document).on('scroll', function () {
      var scrollDistance = $(this).scrollTop();
      if (scrollDistance > 100) {
        $('.scroll-to-top').fadeIn();
      } else {
        $('.scroll-to-top').fadeOut();
      }
    });

    // Smooth scrolling using jQuery easing
    $(document).on('click', 'a.scroll-to-top', function (e) {
      var $anchor = $(this);
      $('html, body')
        .stop()
        .animate(
          {
            scrollTop: $($anchor.attr('href')).offset().top,
          },
          1000,
          'easeInOutExpo',
        );
      e.preventDefault();
    });

    // Detail Collapse event
    $(document).on('click', '.btn-detail-collapse', function () {
      let $detailWrap = $(this).parents('.detail-wrap');
      let $detailOriginalWrap = $(this).parents('.detail-original-wrap');
      $detailWrap.toggleClass('collapsed');

      if ($detailWrap.hasClass('collapsed')) {
        $detailOriginalWrap.children().fadeIn(0);
      } else {
        $detailOriginalWrap.children().fadeOut(600);
      }
    });
  },
  /**
   * Close any open menu accordions when window is resized below 768px
   */
  setLayout() {
    if ($(window).width() < 768) {
      $('.sidebar .collapse').collapse('hide');
    }

    // Toggle the side navigation when window is resized below 480px
    if ($(window).width() < 480 && !$('.sidebar').hasClass('toggled')) {
      $('body').addClass('sidebar-toggled');
      $('.sidebar').addClass('toggled');
      $('.sidebar .collapse').collapse('hide');
    }
  },
  /**
   * Set Datepicker
   */
  setDatepicker() {
    let $datepicker = $('.datepicker');
    $datepicker.datepicker({ dateFormat: 'yy년 m월 d일' });
    $datepicker.datepicker().datepicker('setDate', 'today');
  },
  /**
   * Set Validation
   */
  setValidation() {
    let elements = document.querySelectorAll('input, textarea');

    for (let i = 0; i < elements.length; i++) {
      elements[i].oninvalid = function () {
        // e.target.setCustomValidity('');
        elements[i].classList.remove('is-valid');
        elements[i].classList.add('is-invalid');
      };
      elements[i].oninput = function (e) {
        if (e.target.required) {
          if (e.target.validity.valid) {
            elements[i].classList.remove('is-invalid');
            elements[i].classList.add('is-valid');
          } else {
            elements[i].classList.remove('is-valid');
            elements[i].classList.add('is-invalid');
          }
        }
      };
    }
  },
  /**
   * Set Custom Select
   */
  setCustomDropdownSelect() {
    let $customSelectWrap = $('.ta-custom-dropdown-select-wrap');
    $(document).off('click', '.ta-custom-dropdown-select-wrap .custom-dropdown-select .dropdown-item');
    $(document).on('click', '.ta-custom-dropdown-select-wrap .custom-dropdown-select .dropdown-item', (e) => {
      let $this = $(e.target);
      let $originalSelect = $this.parents('.ta-custom-dropdown-select-wrap').find('select');
      let $originalOptionList = $originalSelect.find('option:not([value="_placeholder"]');
      let $index = $this.parents('.dropdown-menu').find('.dropdown-item').index($this);
      let $btn = $this.parents('.custom-dropdown-select').find('.btn');
      $originalOptionList.eq($index).prop('selected', true);
      $btn.text($this.text());
      $btn.parent().removeClass('dropdown-placeholder');
      $this.parents('.dropdown-menu').find('.dropdown-item').removeClass('active');
      $this.addClass('active');
    });
    $customSelectWrap.each((index, el) => {
      let $originalSelect = $(el).find('select');
      let $originalOptgroupList = $originalSelect.find('optgroup');
      let $originalOptionList = $originalSelect.find('option');
      let isPlaceholder = false;
      if ($(el).find('.custom-dropdown-select').length <= 0) {
        let newSelect = `
        <div class="dropdown custom-dropdown-select">
        <button class="btn dropdown-toggle" type="button" id="${$originalSelect.attr('id')}Dropdown" data-toggle="dropdown" aria-expanded="false">
        선택해주세요.
        </button>
        <div class="dropdown-menu" aria-labelledby="${$originalSelect.attr('id')}Dropdown">
        `;
        if ($originalOptgroupList.length > 0) {
          for (let i = 0; i < $originalOptgroupList.length; i++) {
            $originalOptionList = $originalOptgroupList.eq(i).find('option');
            newSelect += `
            <div class="dropdown-optgroup">
            <h6>${$originalOptgroupList[i].label}</h6>
            `;
            for (let j = 0; j < $originalOptionList.length; j++) {
              if ($originalOptionList[j].value == '_placeholder') {
                isPlaceholder = true;
              } else {
                newSelect += `
                <a href="javascript:void(0)"
                class="dropdown-item ${$($originalOptionList[j]).prop('selected') ? 'active' : ''}"
                data-value="${$originalOptionList[j].value}">${$originalOptionList[j].text}</a>
                `;
              }
            }
            newSelect += '</div>';
          }
        } else {
          for (let i = 0; i < $originalOptionList.length; i++) {
            if ($originalOptionList[i].value == '_placeholder') {
              isPlaceholder = true;
            } else {
              newSelect += `
              <a href="javascript:void(0)"
              class="dropdown-item ${$($originalOptionList[i]).prop('selected') ? 'active' : ''}"
              data-value="${$originalOptionList[i].value}">${$originalOptionList[i].text}</a>
              `;
            }
          }
        }
        newSelect += `
        </div>
        </div>
        `;
        $originalSelect.after(newSelect);

        if ($(el).find('a.dropdown-item.active').length > 0) {
          $('#' + $originalSelect.attr('id') + 'Dropdown').text($(el).find('a.dropdown-item.active').text());
        } else {
          if (isPlaceholder) {
            $(el).find('.custom-dropdown-select').addClass('dropdown-placeholder');
          }
        }
      }
    });
  },
  /**
   * Set Table Checkbox
   */
  setTableCheckbox() {
    $(document).on('change', '.dropdown-table-filter input.all', (e) => {
      let $dropdown = $(e.target).parents('.dropdown-table-filter');
      let $eachCheckboxList = $dropdown.find('input.each');
      $eachCheckboxList.prop('checked', !$(e.target).prop('checked'));
      $eachCheckboxList.trigger('click');
    });
    $(document).on('change', '.dropdown-table-filter input.each', (e) => {
      let $dropdown = $(e.target).parents('.dropdown-table-filter');
      let $allCheckbox = $dropdown.find('input.all');
      let $eachLength = $dropdown.find('input.each').length;
      let $eachCheckedLength = $dropdown.find('input.each:checked').length;
      if ($eachLength == $eachCheckedLength) {
        $allCheckbox.prop('checked', true);
      } else {
        $allCheckbox.prop('checked', false);
      }
    });
  },
  /**
   * Set Filter Checkbox
   */
  setCustomSelectTab() {
    $(document).on('change', '.custom-select-tab', (e) => {
      let $parent = $(e.target).parent();
      let $customTab = $parent.find('.custom-tab');
      let $navLink = $customTab.find('.nav-link');
      let selectedIndex = $(e.target).find('option:selected').index();

      $navLink.eq(selectedIndex).parent().trigger('click');
    });
  },
  /**
   * Set Filter Checkbox
   */
  setFilterCheckbox() {
    $(document).on('change', '.filter-checkbox-wrap.custom input[type="checkbox"]', (e) => {
      let $filterWrap = $(e.target).parents('.filter-checkbox-wrap.custom');

      // if (!$(e.target).prop('checked')) {
      //   let $otherFilterList = $filterWrap.siblings().filter(function () {
      //     return $(this).hasClass('custom');
      //   });
      //   if (!$otherFilterList.eq(0).find('input[type="checkbox"]').prop('checked')) {
      //     $otherFilterList.eq(0).find('input[type="checkbox"]').trigger('click');
      //   }
      // }
      let $otherFilterList = $filterWrap.siblings().filter(function () {
        return $(this).hasClass('custom');
      });
      if ($(e.target).prop('checked') == $otherFilterList.eq(0).find('input[type="checkbox"]').prop('checked')) {
        $otherFilterList.eq(0).find('input[type="checkbox"]').trigger('click');
      }
    });
    $(document).on('change', '.dropdown-table-filter input.each', (e) => {
      let $dropdown = $(e.target).parents('.dropdown-table-filter');
      let $allCheckbox = $dropdown.find('input.all');
      let $eachLength = $dropdown.find('input.each').length;
      let $eachCheckedLength = $dropdown.find('input.each:checked').length;
      if ($eachLength == $eachCheckedLength) {
        $allCheckbox.prop('checked', true);
      } else {
        $allCheckbox.prop('checked', false);
      }
    });
  },
  /**
   * Set Tooltip
   */
  setTooltip() {
    $('[data-toggle="tooltip"]').tooltip({
      content: function () {
        return $(this).prop('title');
      },
    });
  },
  /**
   * Set Images Upload
   */
  setImgUpload() {
    $(document).on('change', '.img-upload-file input[type="file"]', function (e) {
      e.stopPropagation();

      const $parent = $(this).parents('.img-upload-wrap');
      const $dataNone = $parent.find('.data-none');
      const $imgRow = $parent.find('.img-upload-list .row');
      const $imgCount = $parent.find('.img-upload-list .img-count');
      const $copyFileInput = $(this).clone();
      $copyFileInput.attr('name', 'imgFile');
      $copyFileInput.attr('id', '');
      $copyFileInput.attr('hidden', true);

      if (e.target.files[0] != null) {
        for (let i = 0; i < e.target.files.length; i++) {
          let fileName = e.target.files[i].name;
          let name = e.target.files[i].name.substring(0, e.target.files[i].name.lastIndexOf('.'));

          let reader = new FileReader();

          reader.onload = function (event) {
            let newImg = `
            <div class="col-4 col-sm-3">
            <div class="img-item" data-name="${fileName}">
            <img src="${event.target.result}" alt="${name}">
            </div>
            <button type="button" class="btn-icon btn-remove-img"><i class="xi-close"></i></button>
            </div>
            `;
            let newFileInput = `
            <input type="file" name="imgFile" title="${e.target.files[i].name}" hidden>
            `;
            $imgRow.append(newImg);
            $imgRow.find('.img-item').last().append(newFileInput);
            $imgCount.text($imgRow.find('.img-item').length);

            let dataTranster = new DataTransfer();
            Array.from($copyFileInput[0].files)
              .filter((file, index) => {
                return index == i;
              })
              .forEach((file) => {
                dataTranster.items.add(file);
              });
            $parent.find('.img-item').last().find('input')[0].files = dataTranster.files;
            $dataNone.css('display', 'none');
          };

          reader.readAsDataURL(e.target.files[i]);
        }
      }
    });
    $(document).on('dragover', '.img-upload-file', function () {
      $(this).addClass('active');
    });
    $(document).on('dragleave', '.img-upload-file', function () {
      $(this).removeClass('active');
    });
    $(document).on('drop', '.img-upload-file', function () {
      $(this).removeClass('active');
    });
    $(document).on('click', '.img-upload-list .btn-remove-toggle', function () {
      const $parent = $(this).parents('.img-upload-list');
      $parent.toggleClass('active');

      if ($parent.hasClass('active')) {
        $(this).html('완료');
      } else {
        $(this).html('<i class="xi-trash mr-4"></i>이미지 정리');
      }
    });
    $(document).on('click', '.img-upload-list .btn-remove-img', function () {
      const $parent = $(this).parents('.img-upload-wrap');
      const $dataNone = $parent.find('.data-none');
      const $imgRow = $parent.find('.img-upload-list .row');
      const $imgCount = $parent.find('.img-upload-list .img-count');

      $(this).parent().remove();

      $imgCount.text($imgRow.find('.img-item').length);
      if ($imgRow.find('.img-item').length == 0) {
        $dataNone.css('display', 'flex');
      }
    });
    $(document).on('click', '.img-upload-list .img-item', function () {
      const $parent = $(this).parents('.img-upload-wrap');
      let $imgUploadList = $parent.find('.img-upload-list');
      let $imgItemList = $parent.find('.img-item');

      if (!$imgUploadList.hasClass('active')) {
        $imgItemList.removeClass('active');
        $(this).addClass('active');
      }
    });
  },
  /**
   * Set Tab event
   */
  setTabEvent() {
    $(document).on('click', '.nav-tabs .nav-link', function () {
      let $parent = $(this).closest('.nav-tabs');
      $parent.find('.nav-link').removeClass('active');
      $(this).addClass('active');
    });
  },
  /**
   * Start Loading
   */
  startLoading(type) {
    let wrapElmnt = document.createElement('div');
    wrapElmnt.classList.add('spinner-wrap');
    let loadingElmnt = document.createElement('div');
    loadingElmnt.classList.add('spinner-border');
    let span = document.createElement('span');
    span.classList.add('sr-only');
    span.innerHTML = 'Loading...';
    loadingElmnt.appendChild(span);
    wrapElmnt.appendChild(loadingElmnt);
    wrapElmnt.style.opacity = 1;

    switch (type) {
      case 'list': {
        wrapElmnt.classList.add('list');
        let container = document.getElementsByClassName('container-fluid')[0];
        container.scrollTop = 0;
        container.style.overflow = 'hidden';
        let listWrap = document.querySelector('.list-wrap');
        let tabContent = document.querySelector('.tab-content');
        if (tabContent != null) {
          tabContent.prepend(wrapElmnt);
        } else {
          listWrap.appendChild(wrapElmnt);
        }
        break;
      }
      case 'detail': {
        wrapElmnt.classList.add('detail');
        let tabContent = document.querySelector('.tab-content');
        if (tabContent != null) {
          tabContent.prepend(wrapElmnt);
        }
        // let detailWrap = document.querySelector('.detail-wrap');
        // if (detailWrap != null) {
        //   detailWrap.prepend(wrapElmnt);
        // }
        break;
      }
      case 'table': {
        wrapElmnt.classList.add('table');
        let listWrap = document.querySelector('.list-wrap');
        let tableWrap = document.querySelector('.table-wrap');
        let tableBody = document.querySelector('.table-wrap tbody');
        if (tableWrap != null) {
          tableWrap.style.overflow = 'hidden';
          tableBody.prepend(wrapElmnt);
        } else {
          listWrap.appendChild(wrapElmnt);
        }
        break;
      }
      default: {
        wrapElmnt.classList.add('none');
        let body = document.getElementsByTagName('body')[0];
        body.style.overflow = 'hidden';
        body.appendChild(wrapElmnt);
        break;
      }
    }
  },
  /**
   * End Loading
   */
  endLoading(type) {
    let wrapElmnt = '';
    let container = document.getElementsByClassName('container-fluid')[0];
    let body = document.getElementsByTagName('body')[0];
    let tableWrap = document.getElementsByClassName('table-wrap')[0];

    switch (type) {
      case 'list': {
        wrapElmnt = document.querySelectorAll('.spinner-wrap.list');
        container.style.overflow = 'auto';
        break;
      }
      case 'detail': {
        wrapElmnt = document.querySelectorAll('.spinner-wrap.detail');
        break;
      }
      case 'table': {
        wrapElmnt = document.querySelectorAll('.spinner-wrap.table');
        if (tableWrap) {
          tableWrap.style.overflow = 'overlay';
        }
        break;
      }
      default: {
        wrapElmnt = document.querySelectorAll('.spinner-wrap.none');
        body.style.overflow = 'auto';
        break;
      }
    }

    for (let i = 0; i < wrapElmnt.length; i++) {
      if (wrapElmnt[i].style.opacity != 0) {
        wrapElmnt[i].style.opacity = 0;
        wrapElmnt[i].remove();
        break;
      }
    }
  },
  /**
   * Ajax
   * ex) Utils.request('GET', 'test.json', null, (data) => { console.log(data) });
   * @param {String} type
   * @param {String} url
   * @param data
   * @param {Function} func
   */
  request(type, url, data, func) {
    $.ajax({
      type,
      url,
      data,
      success: (data) => {
        func(data);
      },
      error: (xhr, status, error) => {
        console.log(`status: ${status}\nmessage: ${error}`);
      },
    });
  },
  /**
   * Number format
   * ex) 1000000 -> 1,000,000
   * @param {Number} value
   * @returns {String}
   */
  numberFormatter(value) {
    if (value != '' && value != null && typeof value == 'number') {
      value = String(value)
        .replace(/[^\d]+/g, '')
        .replace(/(^0+)/, '')
        .replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    } else {
      value = 0;
    }
    return value === '' ? 'NaN' : value;
  },
  /**
   * Get input[type=file] detail
   * @param {Element} elmnt
   * @returns {Object}
   */
  getFileDetail(elmnt) {
    //파일 경로.
    let filePath = elmnt.value;
    //전체경로를 \ 나눔.
    let filePathSplit = filePath.split('\\');
    // 파일 전체명
    let originalFileName = filePathSplit[filePathSplit.length - 1];
    //파일확장자 앞 .의 index
    let lastDot = originalFileName.lastIndexOf('.');
    //파일명 : .으로 나눈 앞부분
    let fileName = originalFileName.substring(0, lastDot);
    //파일 확장자 : .으로 나눈 뒷부분
    let fileExt = originalFileName.substring(lastDot + 1, originalFileName.length).toLowerCase();
    //파일 크기
    let fileSize = elmnt.files[0].size;

    let object = {
      originalName: originalFileName,
      name: fileName,
      ext: fileExt,
      size: fileSize,
    };

    return object;
  },
  /**
   * Byte to size
   * return ex) 5 GB
   * @param {Number} byte
   * @returns {String}
   */
  byteFormatter(byte) {
    let sizes = ['Byte', 'KB', 'MB', 'GB', 'TB'];
    if (byte == 0) return '0 Byte';
    let i = parseInt(Math.floor(Math.log(byte) / Math.log(1024)));
    return Math.round(byte / Math.pow(1024, i), 2) + ' ' + sizes[i];
  },
  /**
   * Set date format
   * @param {String} date
   * @returns {Object}
   */
  dateFormatter(date) {
    if ((date == '' || date == null) && typeof date == 'string') {
      return '';
    }
    const addZero = (num, digits) => {
      var zero = '';
      num = num.toString();

      if (num.length < digits) {
        for (var i = 0; i < digits - num.length; i++) {
          zero += '0';
        }
      }
      return zero + num;
    };
    // Safari Invalid Date 로 인한 replace
    date = date.substring(0, date.lastIndexOf('.')).replace(/-/g, '/').replace('T', ' ');
    let newDate = new Date(date);

    let yyyy = newDate.getFullYear();
    let mm = addZero(newDate.getMonth() + 1, 2);
    let m = newDate.getMonth() + 1;
    let dd = addZero(newDate.getDate(), 2);
    let d = newDate.getDate();

    let object = {
      slash: yyyy + '/' + mm + '/' + dd,
      dot: yyyy + '.' + mm + '.' + dd,
      dash: yyyy + '-' + mm + '-' + dd,
      word: yyyy + '년 ' + m + '월 ' + d + '일',
    };

    return object;
  },
};

export default Utils;
