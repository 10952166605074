<template>
  <b-modal id="id_img_modal" size="lg" centered hide-footer>
    <template #modal-header>
      <h2 class="modal-title">{{ title }}</h2>
      <button type="button" aria-label="Close" class="close" @click="$bvModal.hide('id_img_modal')">×</button>
    </template>
    <div class="modal-body-inner text-center pb-40">
      <img :src="imgUrl" :alt="title" class="w-100">
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'AppImageModal',
  props: {
    title: String,
    imgUrl: String,
  },
}
</script>