<template>
  <footer class="sticky-footer p-0">
    <!-- <div class="container my-auto">
        <div class="copyright text-center my-auto">
            <span>Copyright &copy; 2022 All rights reserved.</span>
        </div>
    </div> -->
  </footer>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>