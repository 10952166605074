<template>
  <div class="container-fluid">
    <!-- Page Heading -->
    <div class="d-flex flex-wrap align-items-center justify-content-between mb-32">
      <h2 class="text-gray-600">배너관리</h2>
      <button type="button" class="btn btn-primary btn-icon" v-b-modal.idServiceModal v-if="$store.getters.userInfo.group_name != 'normal'" @click="$eventBus.setValidation()">
        <i class="xi-plus mr-4"></i>
        <span>서비스 추가</span>
      </button>
    </div>
    <div class="list-wrap">
      <div class="list-container" v-if="bannerList != null">
        <ul class="nav nav-tabs main-tab mb-56" v-if="serviceList != null">
          <li class="nav-item" :key="index" v-for="(item, index) in serviceList" @click="serviceTab(item.id)">
            <a href="javascript:void(0)" class="nav-link" :class="{active: item.id == selectedServiceId}" data-toggle="tab">
              {{ item.name }}
              <i class="xi-info text-gray-300" v-if="item.description != null && item.description != ''" v-b-tooltip.hover v-b-tooltip.html="true" :title="'<pre>' + item.description + '</pre>'"></i>
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <!-- <div :id="'mainTab_' + index" class="tab-pane show" :class="{active: index == 0}" :key="index" v-for="(service, index) in bannerList.services"> -->
          <div class="tab-pane show active">
            <div class="row">
              <!-- <div class="col-12 col-lg-6 col-xl-4 mb-32" :key="index" v-for="(content, index) in bannerList.contents.content"> -->
              <div class="col-12 col-lg-6 col-xl-4 mb-32" v-if="$store.getters.userInfo.group_name != 'normal'">
                <router-link :to="'/banner/create/' + selectedServiceId" class="card-add">
                  <div class="content">
                    <i class="xi-plus mb-24"></i>
                    <h3>배너 추가</h3>
                  </div>
                </router-link>
              </div>
              <div class="col-12 col-lg-6 col-xl-4 mb-32" :key="index" v-for="(banner, index) in filterbannerList">
                <div class="card main-list">
                  <div class="card-body">
                    <!-- <div class="card-filter-list">
                      <span class="badge">순서: {{ banner.ordering }}</span>
                    </div> -->
                    <b-dropdown right class="dropdown-table-filter no-arrow ml-8">
                      <template #button-content>
                          <i class="xi-ellipsis-v"></i>
                      </template>
                      <b-dropdown-item @click="goDetail(banner.id)">
                        <span v-if="$store.getters.userInfo.group_name != 'normal'">배너 수정</span>
                        <span v-else>배너 상세</span>
                      </b-dropdown-item>
                      <b-dropdown-item v-if="$store.getters.userInfo.group_name != 'normal'" @click="deleteBanner(banner.id, banner.title, banner.ordering)">
                        <span>배너 삭제</span>
                      </b-dropdown-item>
                    </b-dropdown>
                    <a href="javascript:void(0)" class="btn-img-wrap d-block h-100" @click="changeModalData(banner.title, banner.image)" v-b-modal.id_img_modal>
                      <div class="img-wrap h-100" :style="`background: url(${$axios.defaults.baseURL}/media/${banner.image}) no-repeat center / cover;`">
                          <span class="sr-only">이미지</span>
                      </div>
                    </a>
                  </div>
                  <div class="card-footer">
                    <a href="javascript:void(0)" class="card-title" @click="goDetail(banner.id)">{{ banner.title }}</a>
                    <p class="p2 text-gray-400 text-truncate">{{ banner.url }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="data-none" v-if="$store.getters.userInfo.group_name == 'normal' && bannerList.length == 0">
              <p>배너 내역이 없습니다.</p>
            </div>
          </div>
          <Pagination type="banner" :total="bannerList.length" v-on:sendParent="getBannerListByPage" />
        </div>
      </div>
      <div class="data-none" v-if="serviceList != null && serviceList.length == 0">
        <p>등록된 서비스가 없습니다.</p>
      </div>
    </div>
    <ImageModal :title="selectedContentTitle" :imgUrl="selectedContentImgUrl" />
    <ServiceModal title="서비스 추가"/>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue'
import ImageModal from '@/components/modal/ImageModal.vue';
import ServiceModal from '@/components/modal/ServiceModal.vue';
export default {
  name: 'AppList',
  data() {
    return {
      bannerList: null,
      serviceList: null,
      filterbannerList: null,
      selectedServiceId: 1,
      selectedContentTitle: null,
      selectedContentImgUrl: null,
      currentPage: 1,
    }
  },
  components: {
    Pagination,
    ImageModal,
    ServiceModal
  },
  methods: {
    serviceTab(serviceId) {
      if(this.selectedServiceId != serviceId) {
        this.currentPage = 1;
        this.selectedServiceId = serviceId;
        this.getBannerList();
      }
    },
    getServiceList() {
      this.$axios.get('/api/service', {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      })
      .then((res) => {
        this.serviceList = res.data.service;
        if(this.serviceList.length > 0) {
          this.copyTarget = res.data.service[0].id;
        }
      })
      // .catch(() => {
      //   console.log(err.response.data);
      // });
    },
    getBannerListByPage(currentPage, perPage){
      this.currentPage = currentPage;
      this.filterbannerList = [];

      this.filterbannerList = this.bannerList.filter((el, index) => {
        if((currentPage - 1) * perPage <= index && currentPage * perPage > index) {
          return true;
        } else {
          return false;
        }
      });
    },
    getBannerList() {
      this.$utils.startLoading('list');

      this.$axios.get(`/api/banner?service=${this.selectedServiceId}`, {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      })
      .then((res) => {
        this.bannerList = res.data;
        this.bannerList = this.bannerList.filter(function(el) {
          return el.is_active;
        });
        this.currentPage = 1;
        this.getBannerListByPage(this.currentPage, 8);
        this.$utils.endLoading('list');
        this.$utils.endLoading();
      })
      .catch(() => {
        // console.log(err.response.data);
        this.$utils.endLoading('list');
        this.$utils.endLoading();
      })
    },
    getBannerListFirst() {
      this.$axios.get('/api/service', {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      })
      .then((res) => {
        if(res.data.service != null && res.data.service.length > 0) {
          // this.selectedServiceId = res.data.service[0].id;
          this.getBannerList();
        } else {
          this.bannerList = {};
        }
      })
      // .catch(() => {
        // console.log(err.response.data);
      // });
    },
    deleteBanner(id, title, ordering) {
      const formData = new FormData();
      formData.append('ordering', ordering);
      formData.append('is_active', false);
      
      this.$root.$emit('showModalAlert', 'danger', `정말 '${title}'을(를)\n삭제 하시겠습니까?`, () => {
        this.$axios.post(`/api/banner/${id}`, formData, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.getters.token
          }
        })
        .then(() => {
          this.$root.$emit('showToast', 'success', '삭제를 완료하였습니다.');
          this.getBannerList();
        })
        .catch(() => {
          // console.log(err.response.data);
          this.$root.$emit('showToast', 'danger', '삭제를 실패하였습니다.');
        })
      });
    },
    changeModalData(title, url) {
      this.selectedContentTitle = title;
      this.selectedContentImgUrl = `${this.$axios.defaults.baseURL + '/media/' + url}`;
    },
    goDetail(id) {
      console.log(id);
      this.$router.push({ name: 'bannerDetail', params: {id, serviceId: this.selectedServiceId} });
    },
    setPageInfo(){
      let pageInfo = this.$store.getters.pageInfo;
      if(this.$route.name.includes(pageInfo.name)) {
        this.selectedServiceId = pageInfo.selectedServiceId,
        this.currentPage = pageInfo.currentPage;
        this.$eventBus.updateCurrentPage(pageInfo.currentPage);
      }
    }
  },
  mounted() {
    this.setPageInfo();
    this.getServiceList();
    this.getBannerListFirst();
    this.$eventBus.$on('updateList' ,() => {
      this.getServiceList();
    })
  },
  updated() {
    this.$store.commit('setPageInfo', {
      name: 'banner',
      selectedServiceId: this.selectedServiceId,
      currentPage: this.currentPage,
    });
    this.$eventBus.updateCurrentPage(this.currentPage);
  },
}
</script>
