<template>
  <div class="container-fluid pb-0">
    <div class="d-flex align-items-center justify-content-between mb-32">
      <h2 class="text-gray-600">마이페이지</h2>
    </div>
    <div class="mypage-row-wrap">
      <div class="row">
        <div class="col-12 col-lg-6 mb-32">
          <div class="mypage-wrap">
            <form id="idUserForm" @submit.prevent="updateUser">
              <h3 class="mb-32">기본정보</h3>
              <div class="form-group mb-24">
                <label for="id_username">이름</label>
                <input type="text" class="form-control" name="name" id="idName" required  placeholder="이름" v-model="name">
              </div>
              <div class="form-group mb-49">
                <label for="id_userid">이메일</label>
                <input type="email" class="form-control" name="username" id="idUsername" required placeholder="회사 이메일" v-model="userName" disabled>
              </div>
              <div class="form-group mb-32">
                <label for="id_userid">소속그룹</label>
                <!-- <select name="group" id="idGroup" class="form-control" required v-model="group" disabled>
                  <option :value="null" disabled selected>소속그룹을 선택해주세요.</option>
                  <option :value=item.id :key="item.id" v-for="(item) in groupList">{{item.name}}</option>
                </select> -->
                <select name="group" id="idGroup" class="form-control" required v-model="group" disabled>
                  <option :value="$store.getters.userInfo.group_id" selected v-if="$store.getters.userInfo.group_name == 'normal'">기본 사용자</option>
                  <option :value="$store.getters.userInfo.group_id" selected v-if="$store.getters.userInfo.group_name == 'design'">디자인</option>
                  <option :value="$store.getters.userInfo.group_id" selected v-if="$store.getters.userInfo.group_name == 'marketing'">마케팅</option>
                  <option :value="$store.getters.userInfo.group_id" selected v-if="$store.getters.userInfo.group_name == 'dif'">디이프</option>
                  <option :value="$store.getters.userInfo.group_id" selected v-if="$store.getters.userInfo.group_name == 'master'">관리자</option>
                </select>
              </div>
              <button class="btn btn-primary">저장하기</button>
            </form>
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-32">
          <div class="mypage-wrap">
            <form id="idPasswordForm" @submit.prevent="updatePassword">
              <h3 class="mb-32">비밀번호 재설정</h3>
              <div class="form-group mb-24">
                <label for="idOldPassword">기존 비밀번호</label>
                <input type="password" class="form-control" name="oldPassword" id="idOldPassword" required  placeholder="기존 비밀번호" v-model="oldPassword">
              </div>
              <div class="form-group mb-24">
                <label for="idNewPassword1">새 비밀번호</label>
                <input type="password" class="form-control" name="newPassword" id="idNewPassword1" required pattern="^.*(?=^.{8,}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[`~!@#$%^&\*\-_+=<>?{}\[\]\/\|\\]).*$" placeholder="새 비밀번호" v-model="newPassword1">
                <div class="feedback">문자, 숫자, 기호를 포함하여 8자 이상을 사용해주세요.</div>
              </div>
              <div class="form-group mb-32">
                <label for="idNewPassword2">새 비밀번호 확인</label>
                <input type="password" class="form-control" name="newPassword2" id="idNewPassword2" required pattern="^.*(?=^.{8,}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[`~!@#$%^&\*\-_+=<>?{}\[\]\/\|\\]).*$" placeholder="새 비밀번호 확인" v-model="newPassword2">
              </div>
              <button class="btn btn-primary">비밀번호 변경하기</button>
            </form>
          </div>
        </div>
      </div>
      <div class="bottom-content">
        <button type="button" class="btn-link text-dark" @click="deleteUser">회원 탈퇴하기</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppMypage',
  data() {
    return {
      // groupList: [],
      name: this.$store.getters.userInfo.name,
      userName: this.$store.getters.userInfo.email,
      group: this.$store.getters.userInfo.group_id,
      oldPassword: null,
      newPassword1: null,
      newPassword2: null,
    }
  },
  methods: {
    getGroupList() {
      this.$axios.get('/api/user/group', {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      })
      .then((res) => {
        for(let i = 0; i < res.data.data.length; i++) {
          if(res.data.data[i].name == 'normal') {
            res.data.data[i].name = '기본 사용자';
          } else if(res.data.data[i].name == 'design') {
            res.data.data[i].name = '디자인';
          } else if(res.data.data[i].name == 'dif') {
            res.data.data[i].name = '디이프';
          } else if(res.data.data[i].name == 'marketing') {
            res.data.data[i].name = '마케팅';
          } else if(res.data.data[i].name == 'master') {
            res.data.data[i].name = '관리자';
          }
          this.groupList.push(res.data.data[i]);
        }
      })
    },
    updateUser() {
      if(this.name == this.$store.getters.userInfo.name) {
        this.$root.$emit('showToast', 'danger', '변경된 정보가 없습니다.');
        document.querySelector('#idName').classList.add('is-invalid');
        document.querySelector('#idName').focus();
      } else {
        const formData = new FormData();
        formData.append('name', this.name);
        formData.append('username', this.userName);
        formData.append('email', this.userName);

        this.$axios.patch('/api/user/info', formData, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.getters.token
          }
        })
        .then(() => {
          this.$root.$emit('showToast', 'success', '변경된 정보를 저장하였습니다.');
          
          this.$store.commit('setUserInfo', {
            name: this.name,
            email: this.userName,
            group_id: this.group,
            group_name: this.$store.getters.userInfo.group_name,
            is_passwrod_reset: this.$store.getters.userInfo.is_password_reset
          });

          let inputList = document.querySelectorAll('#idUserForm input');
          for(let i = 0; i < inputList.length; i++) {
            inputList[i].classList.remove('is-valid');
          }
        })
        .catch(() => {
          // console.log(err.response.data);
          this.$root.$emit('showToast', 'danger', '입력된 정보를 확인해주세요.');
        })
      }
    },
    updatePassword() {
      if(this.newPassword1 == this.newPassword2) {
        const formData = new FormData();
        formData.append('old_password', this.oldPassword);
        formData.append('new_password1', this.newPassword1);
        formData.append('new_password2', this.newPassword2);

        this.$axios.patch('/api/user/password', formData, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.getters.token
          }
        })
        .then(() => {
          this.$root.$emit('showToast', 'success', '변경된 정보를 저장하였습니다.');
          this.oldPassword = null;
          this.newPassword1 = null;
          this.newPassword2 = null;

          let inputList = document.querySelectorAll('#idPasswordForm input');
          for(let i = 0; i < inputList.length; i++) {
            inputList[i].classList.remove('is-valid');
          }
        })
        .catch((err) => {
          // console.log(err.response.data);
          this.$root.$emit('showToast', 'danger', err.response.data.msg);
        })
      } else {
        this.$root.$emit('showToast', 'danger', '새 비밀번호가 일치하지 않습니다.');
        document.querySelector('#idNewPassword2').classList.add('is-invalid');
        document.querySelector('#idNewPassword2').focus();
      }
    },
    deleteUser() {
      this.$root.$emit('showModalAlert', 'deleteUser', '정말 회원탈퇴 하시겠습니까?', () => {
        this.$axios.delete('/api/user/info', {
          headers: {
            'Authorization': 'Bearer ' + this.$store.getters.token
          }
        })
        .then(() => {
          this.$store.commit('deleteToken');
          this.$router.replace({name: 'login'});
          this.$root.$emit('showToast', 'success', '회원 탈퇴를 완료하였습니다.');
        })
        .catch((err) => {
          // console.log(err.response.data);
          this.$root.$emit('showToast', 'danger', err.response.data.msg);
        })
      });
    }
  },
  mounted(){
    this.$utils.setValidation();
    // this.getGroupList();
  }
}
</script>
