<template>
  <div class="container-fluid p-0" v-if="content != null">
    <div class="detail-wrap collapsed">
      <div class="detail-original-wrap">
        <div class="detail-collapse-wrap">
          <button type="button" class="btn-detail-collapse"><i class="xi-angle-left-min"></i></button>
        </div>
        <div class="d-flex flex-wrap align-items-center py-9">
          <h2 class="text-gray-600 mr-24">{{ content.original.name }}</h2>
          <!-- <a href="javascript:void(0)" class="btn-icon btn-sync fs-14">
            <i class="xi-renew fs-18 mr-4"></i>
            <span>{{ content.original.updated_at }}에 동기화</span>
          </a> -->
        </div>
        <div class="d-flex flex-wrap align-itens-center justify-content-between mb-24">
          <!-- <ul class="tag-wrap text-gray-500" v-if="$route.params.contentType == 'dish'">
            <li :key="index" v-for="(item, index) in content.original.nutrient">#{{ item }}</li>
          </ul> -->
          <ul class="tag-wrap text-gray-500">
            <li :key="index" v-for="(item, index) in content.original.nutrient">#{{ item }}</li>
          </ul>
          <div class="d-flex align-items-center">
            <i class="xi-time fs-18 mr-4"></i>
            <p class="p3 font-weight-normal">{{ content.original.updated_at }}에 동기화</p>
          </div>
        </div>
        <div class="detail-readonly-tab mb-24">
          <span class="nav-item">원본 콘텐츠</span>
        </div>
        <div class="img-wrap mb-42" v-if="content.original.image != null" :style="`background: url(${$axios.defaults.baseURL}/media/${content.original.image}) no-repeat center / cover;`">
          <div class="img-info">
            <p class="img-name">{{ content.original.image.substring(0, content.original.image.lastIndexOf('.')).replace('images/', '') }}</p>
            <span class="img-ext">{{ content.original.image.substring(content.original.image.lastIndexOf('.')) }}</span>
          </div>
        </div>
        <div class="detail-readonly-group mb-32">
          <span class="label">헤드카피</span>
          <pre class="detail-readonly">{{ content.original.head_copy }}</pre>
        </div>
        <div class="detail-readonly-group mb-32">
          <span class="label">이미지 출처</span>
          <pre class="detail-readonly">{{ content.original.source }}</pre>
        </div>
        <div class="detail-readonly-group mb-32">
          <span class="label">설명</span>
          <pre class="detail-readonly">{{ content.original.description }}</pre>
        </div>
        <div class="detail-readonly-group mb-32" v-if="$route.params.contentType == 'dish'">
          <span class="label mb-16">식재료별 영양성분 함유량</span>
          <div class="table-responsive">
            <table class="table detail-table">
              <caption class="sr-only">식재료별 영양성분 함유량</caption>
              <colgroup>
                <col style="width: 25%">
                <col style="width: 25%">
                <col style="width: 50%">
              </colgroup>
              <thead>
                <tr>
                  <th>주재료</th>
                  <th>함유량</th>
                  <th>영양성분 Top3</th>
                </tr>
              </thead>
              <tbody>
                <tr :key="index" v-for="(ingredient, index) in content.original.main_ingredient">
                  <td>{{ ingredient.name }}</td>
                  <td v-if="ingredient.amount != ''"><b>{{ ingredient.amount }}</b>{{ ingredient.unit }}</td>
                  <td v-else>{{ ingredient.measurement }}</td>
                  <td>
                    <span :key="index" v-for="(nutrient, index) in ingredient.nutrient.slice(0, 3)">
                      <span v-if="index != 0">, </span>{{ nutrient }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="detail-readonly-group mb-32" v-if="$route.params.contentType == 'dish'">
          <span class="label mb-16">영양성분 함량</span>
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="table-responsive">
                <table class="table detail-table">
                  <caption class="sr-only">영양성분 함량</caption>
                  <tbody>
                    <tr :key="index" v-for="(nutrient, index) in content.original.nutrient_requirements">
                      <td v-if="index < content.original.nutrient_requirements.length / 2">
                        <div class="d-flex align-items-center justify-content-between">
                          <span>{{ Object.keys(nutrient)[0] }}</span>
                          <span v-if="Object.values(nutrient)[0] != '미량'"><b>{{ Object.values(nutrient)[0] }}</b>{{ Object.values(nutrient)[1] }}</span>
                          <span v-else>{{ Object.values(nutrient)[0] }}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="table-responsive">
                <table class="table detail-table">
                  <caption class="sr-only">영양성분 함량</caption>
                  <tbody>
                    <tr :key="index" v-for="(nutrient, index) in content.original.nutrient_requirements">
                      <td v-if="index >= content.original.nutrient_requirements.length / 2">
                        <div class="d-flex align-items-center justify-content-between">
                          <span>{{ Object.keys(nutrient)[0] }}</span>
                          <span v-if="Object.values(nutrient)[0] != '미량'"><b>{{ Object.values(nutrient)[0] }}</b>{{ Object.values(nutrient)[1] }}</span>
                          <span v-else>{{ Object.values(nutrient)[0] }}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="detail-readonly-group mb-32" v-if="$route.params.contentType == 'dish'">
          <span class="label mb-16">식재료 (1인 기준)</span>
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="table-responsive">
                <table class="table detail-table">
                  <caption class="sr-only">식재료 (1인 기준)</caption>
                  <tbody>
                    <tr :key="index" v-for="(item, index) in content.original.ingredient">
                      <td v-if="index < content.original.ingredient.length / 2">
                        <div class="d-flex align-items-center justify-content-between">
                          <span>{{ item.name }}</span>
                          <span v-if="item.amount != ''"><b>{{ item.amount }}</b>{{ item.unit }}</span>
                          <span v-else>{{ item.measurement }}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="table-responsive">
                <table class="table detail-table">
                  <caption class="sr-only">식재료 (1인 기준)</caption>
                  <tbody>
                    <tr :key="index" v-for="(item, index) in content.original.ingredient">
                      <td v-if="index >= content.original.ingredient.length / 2">
                        <div class="d-flex align-items-center justify-content-between">
                          <span>{{ item.name }}</span>
                          <span v-if="item.amount != ''"><b>{{ item.amount }}</b>{{ item.unit }}</span>
                          <span v-else>{{ item.measurement }}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="detail-readonly-group mb-32" v-if="$route.params.contentType == 'dish'">
          <span class="label">조리법</span>
          <pre class="detail-readonly">{{ content.original.recipe }}</pre>
        </div>
      </div>
      <div class="detail-new-wrap">
        <input type="text" name="title" id="idTitle" class="form-control detail-title mb-12" placeholder="콘텐츠 이름을 입력해주세요." v-model="content.service_data.name" :disabled="activeBtnText == '' || activeBtnText == '반영요청'">
        <div class="d-flex flex-wrap align-itens-center justify-content-between mb-24">
          <ul class="tag-wrap">
            <li :key="index" v-for="(item, index) in content.service_data.ingredien">#{{ item }}</li>
          </ul>
          <div class="d-flex align-items-center" v-if="content.service_data.request_time != null">
            <i class="xi-time fs-18 mr-4"></i>
            <p class="p3 font-weight-normal">{{ content.service_data.request_time }}에 수정</p>
          </div>
        </div>
        <ul class="nav nav-tabs detail-tab mb-12">
          <li class="nav-item mb-12" :key="index" v-for="(item, index) in serviceList">
            <a :href="'#idDetailTab_' + item.id" data-toggle="tab" class="nav-link" :class="{active:$route.params.serviceId == item.id}" @click="getContent(item.id)">{{ item.name }}</a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane show active">
            <div class="img-wrap mb-42">
              <div class="img-main w-100 h-100" v-if="content.service_data.image != null" :style="`background: url(${$axios.defaults.baseURL}/media/${content.service_data.image}) no-repeat center / cover;`"><span class="sr-only">메인 이미지</span></div>
              <div class="img-info pr-56">
                <p class="img-name" v-if="content.service_data.image != null">{{ content.service_data.image.substring(0, content.service_data.image.lastIndexOf('.')).replace('images/', '') }}</p>
                <span class="img-ext" v-if="content.service_data.image != null">{{ content.service_data.image.substring(content.service_data.image.lastIndexOf('.')).replace('images/', '') }}</span>
                <a href="javascript:void(0)" class="btn-upload-img" v-b-modal.id_img_upload_modal @click="$eventBus.getImgList()" v-if="content.service_data.review_status != 'unmodify' && $store.getters.userInfo.group_name != 'normal'"><i class="xi-cloud-upload"></i></a>
              </div>
            </div>
            <div class="form-group mb-32">
              <label for="idHeadCopy">헤드카피</label>
              <input type="text" name="headCopy" id="idHeadCopy" class="form-control" placeholder="헤드카피를 입력해주세요." v-model="content.service_data.head_copy" :disabled="activeBtnText == '' || activeBtnText == '반영요청'">
            </div>
            <div class="form-group mb-32">
              <label for="idImgOrigin">이미지 출처</label>
              <input type="text" name="imgOrigin" id="idImgOrigin" class="form-control" placeholder="이미지 출처를 입력해주세요." v-model="content.service_data.source" :disabled="activeBtnText == '' || activeBtnText == '반영요청'">
            </div>
            <div class="form-group mb-32">
              <div class="d-flex align-items-center justify-content-between mb-10">
                <label for="idDesc" class="mb-0">설명</label>
                <button type="button" class="text-primary" v-if="activeBtnText != '' && activeBtnText != '반영요청'" @click="getGrammar('idDesc')">맞춤법 검사하기</button>
              </div>
              <textarea name="desc" id="idDesc" class="form-control" data-grammar="grammarDsc" placeholder="설명을 입력해주세요." v-model="content.service_data.description" :disabled="activeBtnText == '' || activeBtnText == '반영요청'"></textarea>
              <div class="grammar-wrap" :class="{'is-valid': grammarDscValid}" v-if="grammarDsc != ''">
                <pre v-html="grammarDsc"></pre>
              </div>
              <div class="textarea-comment-wrap" v-if="isActiveDscComment && $route.params.contentType == 'dish'">
                <textarea name="dscComment" id="idDscComment" class="form-control" placeholder="코멘트를 남겨주세요." v-model="content.service_data.dsc_comment" :disabled="$store.getters.userInfo.group_name != 'dif'"></textarea>
              </div>
              <div class="textarea-comment-wrap" v-if="isActiveComment && $route.params.contentType == 'food'">
                <textarea name="comment" id="idComment" class="form-control" placeholder="코멘트를 남겨주세요." v-model="content.service_data.comment" :disabled="$store.getters.userInfo.group_name != 'dif'"></textarea>
              </div>
            </div>
            <div class="form-group mb-32" v-if="$route.params.contentType == 'dish'">
              <div class="d-flex align-items-center justify-content-between mb-10">
                <label for="idRecipe" class="mb-0">조리법</label>
                <button type="button" class="text-primary" v-if="activeBtnText != '' && activeBtnText != '반영요청'" @click="getGrammar('idRecipe')">맞춤법 검사하기</button>
              </div>
              <textarea name="recipe" id="idRecipe" class="form-control" data-grammar="grammarRcp" placeholder="조리법을 입력해주세요." v-model="content.service_data.recipe" :disabled="activeBtnText == '' || activeBtnText == '반영요청'"></textarea>
              <div class="grammar-wrap" :class="{'is-valid': grammarRcpValid}" v-if="grammarRcp != ''">
                <pre v-html="grammarRcp"></pre>
              </div>
              <div class="textarea-comment-wrap" v-if="isActiveRcpComment">
                <textarea name="rcpComment" id="idRcpComment" class="form-control" placeholder="코멘트를 남겨주세요." v-model="content.service_data.rcp_comment" :disabled="$store.getters.userInfo.group_name != 'dif'"></textarea>
              </div>
            </div>
            <div class="form-group mb-32">
              <div>
                <label for="idTags" class="mb-10">태그</label>
                <b-form-tags input-name="tags" input-id="idTags" tag-variant="info" :tag-validator="tagValidator" add-button-text="추가" add-button-variant="outline-info" remove-on-delete placeholder="" v-model="tagList" :disabled="activeBtnText == '' || activeBtnText == '반영요청'"></b-form-tags>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-wrap">
          <a href="javascript:void(0)" class="btn btn-outline small" @click="$router.push({name: 'contentList'})">목록으로</a>
          <a href="javascript:void(0)" class="btn btn-outline small" @click="updateContent('refuse')" v-if="$store.getters.userInfo.group_name == 'dif' && (content.service_data.review_status == 'assessment' || content.service_data.review_status == 'reassessment')">반려하기</a>
          <a href="javascript:void(0)" class="btn btn-primary small" @click="updateContent" v-if="activeBtnText != ''" v-text="activeBtnText"></a>
        </div>
      </div>
    </div>
    <ImageUploadModal :title="'\'' + content.service_data.name + '\' 사진 업로드'" :serviceId="content.service_data.service" :contentType="$route.params.contentType" :mainImgId = "content.service_data.image_id"/>
  </div>
</template>

<script>
import ImageUploadModal from "@/components/modal/ImageUploadModal.vue";
export default {
    name: "AppDetail",
    data() {
      return {
        serviceList: null,
        content: null,
        tagList: [],
        successMsg: '',
        activeBtnText: '',
        isActiveComment: true,
        isActiveDscComment: true,
        grammarDsc: '',
        grammarDscValid: false,
        isActiveRcpComment: true,
        grammarRcp: '',
        grammarRcpValid: false,
      };
    },
    components: {
      ImageUploadModal
    },
    methods: {
      getGrammar(id) {
        let el = document.querySelector(`#${id}`);
        let text = el.value.replaceAll('\n', ' ㅤ ');
        
        if(el.value != null && el.value.trim() != ''){
          this.$bvToast.hide();
          
          this.$axios.get(`/api/grammar?text=${text}`, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.getters.token
            }
          })
          .then((res) => {
            let isPassed = res.data.data[3] == 0;
            let result = res.data.data[2].replaceAll(' ㅤ ', '\n')
                                        .replaceAll("<span class='green_text'>", "<span class='is-invalid' title='잘못된 간격'>")
                                        .replaceAll("<span class='red_text'>", "<span class='is-invalid' title='잘못된 철자'>")
                                        .replaceAll("<span class='purple_text'>", "<span class='is-invalid' title='모호함'>")
                                        .replaceAll("<span class='blue_text'>", "<span class='is-invalid' title='통계 수정'>");
            
            if(el.dataset.grammar == 'grammarDsc') {
              this.grammarDsc = result;
              this.grammarDscValid = isPassed;
            } else if(el.dataset.grammar == 'grammarRcp') {
              this.grammarRcp = result;
              this.grammarRcpValid = isPassed;
            }
          })
          // .catch((err) => {
          //   console.log(err.response.data);
          // })
        } else {
          el.focus();
          this.$root.$emit('showToast', 'danger', '내용을 입력해주세요.');
        }
      },
      getServiceList() {
        this.$axios.get('/api/service', {
          headers: {
            'Authorization': 'Bearer ' + this.$store.getters.token
          }
        })
        .then((res) => {
          this.serviceList = res.data.service;
        })
        // .catch(() => {
        //   console.log(err.response.data);
        // });
      },
      getContent(serviceId) {
        this.$utils.startLoading('detail');

        let url = '';
        if(serviceId == null) {
          url = `/api/${this.$route.params.contentType}/${this.$route.params.id}?service=${this.$route.params.serviceId}`;
        } else {
          url = `/api/${this.$route.params.contentType}/${this.$route.params.id}?service=${serviceId}`;
        }

        this.$axios.get(url, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.getters.token
          }
        })
        .then((res) => {
          this.content = JSON.parse(res.data.datas);
          if(this.content.service_data.request_time != null) {
            this.content.service_data.request_time = this.$utils.dateFormatter(this.content.service_data.request_time).word;
          }
          this.content.original.updated_at = this.$utils.dateFormatter(this.content.original.updated_at).word;

          if(this.content.service_data.tag != null) {
            for(let i = 0; i < this.content.service_data.tag.length; i++) {
              this.tagList.push(this.content.service_data.tag[i]);
            }
          }

          if(this.content.original.nutrient_requirements != null) {
            for(let i = 0; i < this.content.original.nutrient_requirements.length; i++) {
              let nutrient = this.content.original.nutrient_requirements[i];
              if(Object.values(nutrient)[0] == 0) {
                this.content.original.nutrient_requirements.splice(i, 1);
                i--;
              } else if(Object.values(nutrient)[0] < 0.01) {
                nutrient[Object.keys(nutrient)[0]] = '미량';
              } else {
                nutrient[Object.keys(nutrient)[0]] = Math.round(nutrient[Object.keys(nutrient)[0]] * 100) / 100;
              }
            }
          }
          
          if(this.isJson(this.content.original.recipe)) {
            this.content.original.recipe = JSON.parse(this.content.original.recipe);
            let recipe = '';
            for(let i = 0; i < this.content.original.recipe.length; i++) {
              recipe += this.content.original.recipe[i];
              if(i < this.content.original.recipe.length - 1) {
                recipe += '\n';
              }
            }
            this.content.original.recipe = recipe;
          }
          
          if(this.isJson(this.content.service_data.recipe)) {
            this.content.service_data.recipe = JSON.parse(this.content.service_data.recipe);
            let recipe = '';
            for(let i = 0; i < this.content.service_data.recipe.length; i++) {
              recipe += this.content.service_data.recipe[i];
              if(i < this.content.service_data.recipe.length - 1) {
                recipe += '\n';
              }
            }
            this.content.service_data.recipe = recipe;
          }
          this.setActiveComment();
          this.setActiveButton();
          this.$utils.endLoading('detail');
        })
        .catch(() => {
          // console.log(err.response.data);
          this.$utils.endLoading('detail');
        })
      },
      isJson(data) {
        try {
          let recipe = JSON.parse(data);
          return (typeof recipe === 'object');
        } catch (e) {
          return false;
        }
      },
      updateContent(status) {
        const formData = new FormData();
        formData.append('name', this.content.service_data.name);
        formData.append('service', this.content.service_data.service);
        formData.append('review_status', this.content.service_data.review_status);
        formData.append('image', this.content.service_data.image_id);
        formData.append('source', this.content.service_data.source);
        formData.append('head_copy', this.content.service_data.head_copy);
        formData.append('description', this.content.service_data.description);
        formData.append('recipe', this.content.service_data.recipe);
        formData.append('recipe_source', '');
        formData.append('tag', this.tagList.toString());
        
        if(this.$route.params.contentType == 'dish') {
          formData.append('dsc_comment', this.content.service_data.dsc_comment == null ? '' : this.content.service_data.dsc_comment);
          formData.append('rcp_comment', this.content.service_data.rcp_comment == null ? '' : this.content.service_data.rcp_comment);
        } else if(this.$route.params.contentType == 'food') {
          formData.append('comment', this.content.service_data.comment == null ? '' : this.content.service_data.comment);
        }

        if(this.$store.getters.userInfo.group_name == 'master') {
          formData.append('review_status', 'reflection');
          formData.append('dsc_comment', '');
          formData.append('rcp_comment', '');
          formData.append('comment', '');
          this.successMsg = '반영이 완료되었습니다.';
        } else if(this.$store.getters.userInfo.group_name == 'dif') {
          if(status == 'refuse') {
            formData.append('review_status', 'refuse');
            this.successMsg = '반려가 완료되었습니다.';
          } else {
            if(this.content.service_data.review_status == 'assessment' || this.content.service_data.review_status == 'reassessment') {
              formData.append('review_status', 'approval');
              this.successMsg = '검토가 완료되었습니다.';
            } else {
              formData.append('review_status', 'refuse');
              this.successMsg = '수정요청이 완료되었습니다.';
            }
          }
        } else if(this.$store.getters.userInfo.group_name == 'design' || this.$store.getters.userInfo.group_name == 'marketing') {
          if(this.content.service_data.review_status == 'approval') {
            formData.append('review_status', 'request_reflection');
            this.successMsg = '반영요청이 완료되었습니다.';
          } else if(this.content.service_data.review_status == 'refuse') {
            formData.append('review_status', 'reassessment');
            this.successMsg = '재검토요청이 완료되었습니다.';
          } else {
            formData.append('review_status', 'assessment');
            this.successMsg = '검토요청이 완료되었습니다.';
          }
        }

        this.$axios.post(`/api/${this.$route.params.contentType}/${this.content.service_data.content_id}`, formData, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.getters.token
          }
        })
        .then(() => {
          this.getContent(this.content.service_data.service);
          this.$root.$emit('showToast', 'success', this.successMsg);
        })
        // .catch((err) => {
        //   console.log(err.response.data);
        // })
      },
      updateMainImg(mainImgName) {
        const formData = new FormData();
        formData.append('service', this.content.service_data.service);
        formData.append('image', mainImgName);
        this.$axios.patch(`/api/image/${this.$route.params.id}`, formData, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.getters.token
          }
        })
        .then(() => {
          this.$utils.endLoading('detail');
        })
        .catch(() => {
          // console.log(err.response.data);
          this.$utils.endLoading('detail');
        });
      },
      setActiveButton() {
        if(this.$store.getters.userInfo.group_name == 'master') {
          this.activeBtnText = '반영하기';

          // if(this.content.service_data.review_status == 'request_reflection') {
          //   this.activeBtnText = '반영하기';
          // } else {
          //   this.activeBtnText = '';
          // }
        } else if(this.$store.getters.userInfo.group_name == 'dif') {
          if(this.content.service_data.review_status == 'assessment' || this.content.service_data.review_status == 'reassessment') {
            this.activeBtnText = '검토완료';
          } else {
            this.activeBtnText = '수정요청';
          }
        } else if(this.$store.getters.userInfo.group_name == 'design' || this.$store.getters.userInfo.group_name == 'marketing') {
          if(this.content.service_data.review_status == 'unmodify' || this.content.service_data.review_status == 'reflection') {
            this.activeBtnText = '검토요청';
          } else if(this.content.service_data.review_status == 'refuse') {
            this.activeBtnText = '재검토요청';
          } else if(this.content.service_data.review_status == 'approval') {
            this.activeBtnText = '반영요청';
          }  else {
            this.activeBtnText = '';
          }
        } else {
          this.activeBtnText = '';
        }
      },
      setActiveComment() {
        let comment = this.content.service_data.comment;
        let dscComment = this.content.service_data.dsc_comment;
        let rcpComment = this.content.service_data.rcp_comment;

        if(this.$store.getters.userInfo.group_name != 'dif') {
          if(comment == null || comment == '') {
            this.isActiveComment = false;
          }
          if(dscComment == null || dscComment == '') {
            this.isActiveDscComment = false;
          }
          if(rcpComment == null || rcpComment == '') {
            this.isActiveRcpComment = false;
          }
        }
      },
      tagValidator(tag) {
        return tag.length > 1 && !tag.includes(',');
      },
    },
    mounted() {
      this.getServiceList();
      this.getContent(this.$route.params.serviceId);
      this.$eventBus.$on('updateImgList' ,(mainImgName) => {
        if(mainImgName != null && mainImgName != '') {
          this.$utils.startLoading('detail');
          setTimeout(() => {
            this.updateMainImg(mainImgName);
            this.getContent(this.content.service_data.service);
          }, 3000);
        }
      })
    },
}
</script>
