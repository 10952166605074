import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    token: '',
    refreshToken: '',
    userInfo: {},
    errorInfo: {},
    pageInfo: {},
  },
  getters: {
    token: (state) => {
      return state.token;
    },
    refreshToken: (state) => {
      return state.refreshToken;
    },
    userInfo: (state) => {
      return state.userInfo;
    },
    errorInfo: (state) => {
      return state.errorInfo;
    },
    pageInfo: (state) => {
      return state.pageInfo;
    },
  },
  mutations: {
    insertToken(state, payload) {
      state.token = payload[0];
      state.refreshToken = payload[1];
    },
    deleteToken(state) {
      state.token = '';
      state.refreshToken = '';
      state.userInfo = {};
    },
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
    setErrorInfo(state, payload) {
      state.errorInfo = payload;
    },
    setPageInfo(state, payload) {
      state.pageInfo = payload;
    },
  },
});
