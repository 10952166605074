<template>
  <div id="wrapper">
    <Sidebar v-if="this.$route.name != 'pageNotFound' && this.$route.name != 'error' && !this.$route.path.includes('user')" />

    <div id="content-wrapper" class="d-flex flex-column">

      <!-- main -->
      <div id="content">
        <Header v-if="this.$route.name != 'pageNotFound' && this.$route.name != 'error' && !this.$route.path.includes('user')" />
        
        <router-view/>
      </div>

      <!-- footer -->
      <Footer v-if="this.$route.name != 'pageNotFound' && this.$route.name != 'error' && !this.$route.path.includes('user')" />

      <!-- modal -->
      <Alert :type="modalType" :message="modalMessage" @click-confirm="callback" />

    </div>
  </div>
</template>
<style lang="scss">
  @import '@/assets/scss/main';
</style>
<script>
import Sidebar from '@/components/layout/Sidebar.vue';
import Header from '@/components/layout/Header.vue';
import Footer from '@/components/layout/Footer.vue';
import Alert from '@/components/modal/Alert.vue';
export default {
  name: 'App',
  data() {
    return {
      modalType: null,
      modalMessage: null,
    }
  },
  components: {
    Sidebar,
    Header,
    Footer,
    Alert
  },
  methods: {
    // #region 공통 alarm 함수
    setToast() {
      this.$root.$on('showToast', (type, message) => {
        this.$bvToast.toast(' ', {
          title: message,
          variant: type,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 10000,
          appendToast: false
        });
      });
    },
    setAlert() {
      // 이벤트 등록
      this.$root.$on('showModalAlert', (type, message, callback) => {
        if (type) {
          this.modalType = type;
        }
        if (message) {
          this.modalMessage = message;
        }
        if (callback) {
          this.callback = callback;
        } else {
          this.callback = () => {}
        }
        this.$bvModal.show('idModalAlert');
      });
    },
    callback() {},
    // #endregion 공통 alarm 함수
    // #region 공통 redirect 함수
    // setErrorRedirect() {
    //   this.$root.$on('goError', (err) => {
    //     this.$store.commit('setErrorInfo', {
    //       status: err.response.status,
    //       statusText: err.response.statusText,
    //       data: err.response.data
    //     });
    //     this.$router.replace({ name: 'error' });
    //   })
    // },
    // #endregion 공통 redirect 함수
    checkVerify() {
      const formData = new FormData();
      formData.append('token', this.$store.getters.token);
      formData.append('refresh', this.$store.getters.refreshToken);

      if(this.$store.getters.token) {
        this.$axios.post('/api/token/verify', formData)
        .then(() => {
          if(this.$route.name == 'login') {
            this.$router.replace({name: 'index'});
          }
          this.$axios.post('/api/token/refresh', formData)
            .then((res) => {
              this.$store.commit('insertToken', [res.data.access, this.$store.getters.refreshToken]);
            })
        })
        .catch(() => {
          this.$store.commit('deleteToken');
          if(this.$route.name != 'login') {
            this.$router.replace({name: 'login'});
          }
        });
      } else {
        if(!this.$route.path.includes('user')) {
          this.$router.replace({name: 'login'});
        }
      }
    },
    getUserInfo() {
      this.$axios.get('/api/user/info', {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      })
      .then((res) => {
        if(res.data.data[0].group_name != 'normal' && res.data.data[0].group_name != 'design' &&
           res.data.data[0].group_name != 'marketing' && res.data.data[0].group_name != 'dif' &&
           res.data.data[0].group_name != 'master') {
          res.data.data[0].group_name = 'normal';
        }
        delete res.data.data[0]['password'];
        this.$store.commit('setUserInfo', res.data.data[0]);
      })
      .catch(() => {
        this.$store.commit('deleteToken');
        if(this.$route.name != 'login') {
          this.$router.replace({name: 'login'});
        }
      });
    },
    // 해당 페이지에 관한 정보가 아닐경우 초기화
    resetPageInfo() {
      let pageInfo = this.$store.getters.pageInfo;
      if(pageInfo.name == 'index') {
        if(!this.$route.name.includes('Detail') && !this.$route.name.includes(pageInfo.name)) {
          this.$store.commit('setPageInfo', {});
        }
      } else {
        if(!this.$route.name.includes(pageInfo.name)) {
          this.$store.commit('setPageInfo', {});
        }
      }
    }
  },
  updated() {
    this.checkVerify();
    this.resetPageInfo();
  },
  created() {
    this.checkVerify();
    // 로그인 중일 때만 유저 정보 가져오기
    if(this.$store.getters.token) {
      this.getUserInfo();
    }
  },
  mounted() {
    this.setAlert();
    this.setToast();
  },
}
</script>
