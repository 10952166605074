<template>
  <b-modal id="idModalAlert" centered ok-variant="secondary">
    <template #modal-header>
      <div class="modal-icon-wrap" :class="type">
        <i class="xi-check-circle modal-icon" v-if="type == 'success'"></i>
        <i class="xi-info modal-icon" v-if="type == 'info'"></i>
        <i class="xi-error modal-icon" v-if="type == 'warning'"></i>
        <i class="xi-warning modal-icon" v-if="type == 'danger' || type == 'deleteUser' || type.includes('isActive')"></i>
      </div>
    </template>
    <div class="content-wrap text-center">
      <pre class="mb-0">{{ message }}</pre>
    </div>
    <template #modal-footer>
      <div class="btn-wrap" v-if="type == 'success'">
        <button type="button" class="btn btn-success small" @click="confirm">확인하기</button>
      </div>
      <div class="btn-wrap" v-if="type == 'info'">
      <button type="button" class="btn btn-outline small" @click="$bvModal.hide('idModalAlert')">취소하기</button>
        <button type="button" class="btn btn-info small" @click="confirm">확인하기</button>
      </div>
      <div class="btn-wrap" v-if="type == 'warning'">
        <button type="button" class="btn btn-outline small" @click="$bvModal.hide('idModalAlert')">취소하기</button>
        <button type="button" class="btn btn-warning small" @click="confirm">삭제하기</button>
      </div>
      <div class="btn-wrap" v-if="type == 'danger'">
        <button type="button" class="btn btn-outline small" @click="$bvModal.hide('idModalAlert')">취소하기</button>
        <button type="button" class="btn btn-danger small" @click="confirm">삭제하기</button>
      </div>
      <div class="btn-wrap" v-if="type == 'deleteUser'">
        <button type="button" class="btn btn-outline small" @click="$bvModal.hide('idModalAlert')">취소하기</button>
        <button type="button" class="btn btn-danger small" @click="confirm">회원탈퇴</button>
      </div>
      <div class="btn-wrap" v-if="type.includes('isActive')">
        <button type="button" class="btn btn-outline small" @click="$bvModal.hide('idModalAlert')">취소하기</button>
        <button type="button" class="btn btn-danger small" @click="confirm">{{ type.includes('True') ? '사용' : '미사용' }}</button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'AppAlert',
  props: {
    type: String,
    message: String,
  },
  methods: {
    confirm() {
      this.$bvModal.hide('idModalAlert');
      this.$emit('click-confirm');  // 확인 버튼 클릭 후 이벤트 처리
    }
  },
}
</script>