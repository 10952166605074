<template>
  <div class="container-fluid">
    <!-- Page Heading -->
    <div class="d-flex flex-wrap align-items-center justify-content-between mb-32">
      <h2 class="text-gray-600">식품/음식 콘텐츠</h2>
      <button type="button" class="btn btn-primary btn-icon" v-b-modal.idServiceModal v-if="$store.getters.userInfo.group_name != 'normal'" @click="$eventBus.setValidation()">
        <i class="xi-plus mr-4"></i>
        <span>서비스 추가</span>
      </button>
    </div>
    <div class="list-wrap">
      <div class="list-container" v-if="contentList != null">
        <ul class="nav nav-tabs main-tab mb-32">
          <li class="nav-item" :key="index" v-for="(item, index) in serviceList" @click="serviceTab(item.id)">
            <a href="javascript:void(0)" class="nav-link" :class="{active: item.id == selectedServiceId}" data-toggle="tab">
              {{ item.name }}
              <i class="xi-info text-gray-300" v-if="item.description != null && item.description != ''" v-b-tooltip.hover v-b-tooltip.html="true" :title="'<pre>' + item.description + '</pre>'"></i>
            </a>
          </li>
        </ul>
        <div class="card mb-40">
          <div class="card-body">
            <div class="d-flex flex-wrap align-items-center mt-n6 mb-18">
              <div class="card-filter mr-32" v-if="filterList != null">
                <p class="p3 font-weight-bold mr-12">콘텐츠 종류</p>
                <div class="filter-checkbox-wrap custom">
                  <input type="checkbox" name="food" id="idFood" hidden v-model="selectedContentType.Food" @change="getContentList(1)">
                  <label for="idFood" class="filter-checkbox">식품<span class="filter-count">{{ filterList.food }}</span></label>
                </div>
                <div class="filter-checkbox-wrap custom">
                  <input type="checkbox" name="dish" id="idDish" hidden v-model="selectedContentType.Dish" @change="getContentList(1)">
                  <label for="idDish" class="filter-checkbox">음식<span class="filter-count">{{ filterList.dish }}</span></label>
                </div>
              </div>
              <div class="card-filter" v-if="filterList != null">
                <p class="p3 font-weight-bold mr-12">진행 상황</p>
                <div class="filter-checkbox-wrap">
                  <input type="checkbox" name="status" id="idUnmodify" hidden v-model="selectedStatus.unmodify" @change="getContentList(1)">
                  <label for="idUnmodify" class="filter-checkbox d-flex">
                    <p>원본</p>
                    <span class="filter-count">{{ filterList.unmodify }}</span>
                  </label>
                </div>
                <div class="filter-checkbox-wrap">
                  <input type="checkbox" name="status" id="idAssessment" hidden v-model="selectedStatus.assessment" @change="getContentList(1)">
                  <label for="idAssessment" class="filter-checkbox d-flex">
                    <p>검토요청</p>
                    <span class="filter-count">{{ filterList.assessment }}</span>
                  </label>
                </div>
                <div class="filter-checkbox-wrap">
                  <input type="checkbox" name="status" id="idRefuse" hidden v-model="selectedStatus.refuse" @change="getContentList(1)">
                  <label for="idRefuse" class="filter-checkbox d-flex">
                    <p>반려</p>
                    <span class="filter-count">{{ filterList.refuse }}</span>
                  </label>
                </div>
                <div class="filter-checkbox-wrap">
                  <input type="checkbox" name="status" id="idReassessment" hidden v-model="selectedStatus.reassessment" @change="getContentList(1)">
                  <label for="idReassessment" class="filter-checkbox d-flex">
                    <p>재검토요청</p>
                    <span class="filter-count">{{ filterList.reassessment }}</span>
                  </label>
                </div>
                <div class="filter-checkbox-wrap">
                  <input type="checkbox" name="status" id="idApproval" hidden v-model="selectedStatus.approval" @change="getContentList(1)">
                  <label for="idApproval" class="filter-checkbox d-flex">
                    <p>승인</p>
                    <span class="filter-count">{{ filterList.approval }}</span>
                  </label>
                </div>
                <div class="filter-checkbox-wrap">
                  <input type="checkbox" name="status" id="idRequestReflection" hidden v-model="selectedStatus.request_reflection" @change="getContentList(1)">
                  <label for="idRequestReflection" class="filter-checkbox d-flex">
                    <p>반영요청</p>
                    <span class="filter-count">{{ filterList.request_reflection }}</span>
                  </label>
                </div>
                <div class="filter-checkbox-wrap">
                  <input type="checkbox" name="status" id="idReflection" hidden v-model="selectedStatus.reflection" @change="getContentList(1)">
                  <label for="idReflection" class="filter-checkbox d-flex">
                    <p>반영</p>
                    <span class="filter-count">{{ filterList.reflection }}</span>
                  </label>
                </div>
                <div class="filter-checkbox-wrap">
                  <input type="checkbox" name="status" id="idUnused" hidden v-model="selectedStatus.unused" @change="getContentList(1)">
                  <label for="idUnused" class="filter-checkbox d-flex">
                    <p>미사용</p>
                    <span class="filter-count">{{ filterList.unused }}</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="search-wrap w-100">
              <input type="search" name="searchKeyword" id="idSearchKeyword" class="form-control" placeholder="식품, 음식, 영양성분 등의 다양한 키워드를 검색해보세요." v-model="searchKeyword" v-on:keyup.enter="getContentList(1)">
              <a href="javascript:void(0)" class="text-gray-500 fs-18" @click="getContentList(1)"><i class="xi-search"></i></a>
            </div>
          </div>
        </div>
        <div class="tab-content">
          <div class="tab-pane show active">
            <div class="row">
              <div class="col-12 col-lg-6 col-xl-4 mb-32" :key="index" v-for="(content, index) in contentList">
                <div class="card main-list">
                  <div class="card-body">
                    <div class="card-filter-list">
                      <span class="badge badge-info">{{ content.content_type == 'dish' ? '음식' : '식품' }}</span>
                      <span class="badge" v-if="content.review_status == '' || content.review_status == 'unmodify'">원본</span>
                      <span class="badge" v-if="content.review_status == 'assessment'">검토요청</span>
                      <span class="badge badge-danger" v-if="content.review_status == 'refuse'">반려</span>
                      <span class="badge badge-danger" v-if="content.review_status == 'reassessment'">재검토요청</span>
                      <span class="badge" v-if="content.review_status == 'approval'">승인</span>
                      <span class="badge" v-if="content.review_status == 'request_reflection'">반영요청</span>
                      <span class="badge" v-if="content.review_status == 'reflection'">반영</span>
                      <span class="badge" v-if="content.review_status == 'unused'">미사용</span>
                    </div>
                    <b-dropdown right class="dropdown-table-filter no-arrow ml-8">
                      <template #button-content>
                          <i class="xi-ellipsis-v"></i>
                      </template>
                      <b-dropdown-item @click="goDetail(selectedServiceId, content.content_id)">
                        <span v-if="$store.getters.userInfo.group_name != 'normal'">콘텐츠 수정</span>
                        <span v-else>콘텐츠 상세</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="isActiveContent('True', content.pk, content.content_type, content.name)" v-if="$store.getters.userInfo.group_name != 'normal'  && content.review_status == 'unused'">사용</b-dropdown-item>
                      <b-dropdown-item @click="isActiveContent('False', content.pk, content.content_type, content.name)" v-if="$store.getters.userInfo.group_name != 'normal'  && content.review_status != 'unused'">미사용</b-dropdown-item>
                    </b-dropdown>
                    <a href="javascript:void(0)" class="btn-img-wrap d-block h-100" @click="changeModalData(content.name, content.image)" v-b-modal.id_img_modal>
                      <div class="img-wrap h-100" v-if="content.image != null" :style="`background: url(${$axios.defaults.baseURL}/media/${content.image}) no-repeat center / cover;`">
                          <span class="sr-only">이미지</span>
                      </div>
                    </a>
                  </div>
                  <div class="card-footer">
                    <a href="javascript:void(0)" class="card-title" @click="goDetail(selectedServiceId, content.content_id)">{{ content.name }}</a>
                    <p class="p2 text-gray-400 text-truncate" v-if="content.nutrient.length > 0">#{{ content.nutrient.join('&nbsp;&nbsp; #') }}</p>
                    <p class="p2 text-gray-400 text-truncate" v-else>&nbsp;&nbsp;</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="data-none" v-if="contentList != null && contentList.length == 0">
              <p>콘텐츠 내역이 없습니다.</p>
            </div>
          </div>
          <Pagination type="content" :total="total" v-on:sendParent="getContentList" />
        </div>
      </div>
      <div class="data-none" v-if="serviceList != null && serviceList.length == 0">
        <p>등록된 서비스가 없습니다.</p>
      </div>
    </div>
    <ImageModal :title="selectedContentTitle" :imgUrl="selectedContentImgUrl" />
    <ServiceModal title="서비스 추가"/>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue'
import ImageModal from '@/components/modal/ImageModal.vue';
import ServiceModal from '@/components/modal/ServiceModal.vue';
export default {
  name: 'AppList',
  data() {
    return {
      serviceList: null,
      filterList: null,
      contentList: null,
      filterContentList: null,
      selectedServiceId: 1,
      selectedContentType: {
        Food: true,
        Dish: false,
      },
      selectedStatus: {
        unmodify: false,
        assessment: false,
        refuse: false,
        reassessment: false,
        approval: false,
        request_reflection: false,
        unused: false,
      },
      searchKeyword: '',
      selectedContentTitle: null,
      selectedContentImgUrl: null,
      currentPage: 1,
      total: 0,
    }
  },
  components: {
    Pagination,
    ImageModal,
    ServiceModal
  },
  methods: {
    serviceTab(serviceId) {
      if(this.selectedServiceId != serviceId) {
        this.selectedServiceId = serviceId;
        this.getContentList();
      }
    },
    getServiceList() {
      this.$axios.get('/api/service', {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      })
      .then((res) => {
        this.serviceList = res.data.service;
        if(this.serviceList.length > 0) {
          this.copyTarget = res.data.service[0].id;
        }
      })
      // .catch(() => {
      //   console.log(err.response.data);
      // });
    },
    getFilterList() {
      let url = '';

      // 콘텐츠 종류
      if(this.selectedContentType.Food && this.selectedContentType.Dish) {
        // 현재 이곳은 사용 안하는중
        url = `/api/content?service=${this.selectedServiceId}`;
      } else if(this.selectedContentType.Food && !this.selectedContentType.Dish) {
        url = `/api/food/filter?service=${this.selectedServiceId}`;
      } else if(!this.selectedContentType.Food && this.selectedContentType.Dish) {
        url = `/api/dish/filter?service=${this.selectedServiceId}`;
      } else {
        return;
      }

      this.$axios.get(url, {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      })
      .then((res) => {
        this.filterList = res.data.filter;
      })
      // .catch(() => {
      //   console.log(err.response.data);
      // });
    },
    getContentList(currentPage) {
      if(this.selectedContentType.Food == this.selectedContentType.Dish) {
        return;
      }

      this.getFilterList();
      
      this.$utils.startLoading('list');

      let url = '';

      // 콘텐츠 종류
      if(this.selectedContentType.Food && this.selectedContentType.Dish) {
        // 현재 이곳은 사용 안하는중
        url = `/api/content?service=${this.selectedServiceId}`;
      } else if(this.selectedContentType.Food && !this.selectedContentType.Dish) {
        url = `/api/food?service=${this.selectedServiceId}`;
      } else if(!this.selectedContentType.Food && this.selectedContentType.Dish) {
        url = `/api/dish?service=${this.selectedServiceId}`;
      } else {
        return;
      }

      let status = '';

      for(let key in this.selectedStatus) {
        if(this.selectedStatus[key]) {
          status += key + ',';
        }
      }
      
      // 진행 상황
      if(status != '') {
        url += `&status=${status}`;
      }

      // 페이지
      if(currentPage != null) {
        this.currentPage = currentPage;
        this.$eventBus.updateCurrentPage(this.currentPage);
      }
      url += `&page=${this.currentPage}`;

      // 검색
      if(this.searchKeyword != '') {
        url += `&keyword=${this.searchKeyword}`;
        url = url.replace('/api/food', '/api/food/search').replace('/api/dish', '/api/dish/search');
      }
      
      this.$axios.get(url, {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      })
      .then((res) => {
        this.contentList = JSON.parse(res.data.datas);
        
        if(res.data.count != null) {
          this.total = res.data.count;
        } else {
          if(status != '') {
            let statusArr = status.substring(0, status.lastIndexOf(',')).split(',');
            this.total = 0;
            for(let i = 0; i < statusArr.length; i++) {
              this.total += this.filterList[statusArr[i]];
            }
          } else {
            if(this.contentList.length > 0) {
              if(this.selectedContentType.Food && this.selectedContentType.Dish) {
                this.total = this.filterList.food + this.filterList.dish;
              } else if(this.selectedContentType.Food && !this.selectedContentType.Dish) {
                this.total = this.filterList.food;
              } else if(!this.selectedContentType.Food && this.selectedContentType.Dish) {
                this.total = this.filterList.dish;
              }
            } else {
              this.total = 0;
            }
          }
        }
        this.$utils.endLoading('list');
        this.$utils.endLoading();
      })
      .catch(() => {
        // console.log(err.response.data);
        this.$utils.endLoading('list');
        this.$utils.endLoading();
      })
    },
    getContentListFirst() {
      this.$axios.get('/api/service', {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token
        }
      })
      .then((res) => {
        if(res.data.service != null && res.data.service.length > 0) {
          // this.selectedServiceId = res.data.service[0].id;
          this.getContentList();
        } else {
          this.contentList = {};
        }
      })
      // .catch(() => {
        // console.log(err.response.data);
      // });
    },
    isActiveContent(isActive, id, contentType, title) {
      const formData = new FormData();
      formData.append(`${contentType}_id`, id);
      // formData.append('service', serviceId);

      let msg = `정말 '${title}'을(를)\n`;

      if(isActive == 'True') {
        msg += '사용 하시겠습니까?';
      } else {
        msg += '사용하지 않겠습니까?';
      }
      
      this.$root.$emit('showModalAlert', ('isActive' + isActive), msg, () => {
        this.$axios.post(`/api/content`, formData, {
          headers: {
            'Authorization': 'Bearer ' + this.$store.getters.token
          }
        })
        .then(() => {
          this.$root.$emit('showToast', 'success', `${isActive == 'True' ? '사용' : '미사용'} 처리를 완료하였습니다.`);
          this.getContentList();
        })
        .catch(() => {
          // console.log(err.response.data);
          this.$root.$emit('showToast', 'danger', `${isActive == 'True' ? '사용' : '미사용'} 처리를 실패하였습니다.`);
        })
      });
    },
    changeModalData(title, url) {
      this.selectedContentTitle = title;
      this.selectedContentImgUrl = `${this.$axios.defaults.baseURL}/media/${url}`;
    },
    goDetail(serviceId, id) {
      if(this.selectedContentType.Food && this.selectedContentType.Dish) {
        this.$router.push({ name: 'contentDetail', params: {serviceId, id} });
      } else if(this.selectedContentType.Food && !this.selectedContentType.Dish) {
        this.$router.push({ name: 'contentDetail', params: {serviceId, id, contentType: 'food'} });
      } else if(!this.selectedContentType.Food && this.selectedContentType.Dish) {
        this.$router.push({ name: 'contentDetail', params: {serviceId, id, contentType: 'dish'} });
      }
    },
    setPageInfo(){
      let pageInfo = this.$store.getters.pageInfo;
      if(this.$route.name.includes(pageInfo.name)) {
        this.selectedServiceId = pageInfo.selectedServiceId,
        this.currentPage = pageInfo.currentPage;
        this.total = pageInfo.total;
        this.searchKeyword = pageInfo.searchKeyword;
        this.selectedContentType = pageInfo.selectedContentType;
        this.selectedStatus = pageInfo.selectedStatus;
        this.$eventBus.updateCurrentPage(pageInfo.currentPage);
      }
    }
  },
  mounted() {
    this.setPageInfo();
    this.getServiceList();
    this.getContentListFirst();
    this.$eventBus.$on('updateList' ,() => {
      this.getServiceList();
    })
  },
  updated() {
    this.$store.commit('setPageInfo', {
      name: 'content',
      selectedServiceId: this.selectedServiceId,
      currentPage: this.currentPage,
      total: this.total,
      searchKeyword: this.searchKeyword,
      selectedContentType: this.selectedContentType,
      selectedStatus: this.selectedStatus,
    });
    this.$eventBus.updateCurrentPage(this.currentPage);
  },
}
</script>
