<template>
  <div class="h-screen login-bg">
    <!-- Outer Row -->
    <div class="container h-100 d-flex align-items-center justify-content-center">
      <div class="card o-hidden border-0">
        <div class="card-body p-40">
          <h2 class="login-top text-gray-600 mb-4 d-flex align-items-center mb-16">
            <span>임시 비밀번호 발송</span>
          </h2>
          <p class="p2 mb-16">
            <b>김정환</b>님의 비밀번호 입니다.<br>
            로그인 후 마이페이지에서 비밀번호를 변경해주세요.
          </p>
          <form class="user" @submit.prevent="login">
            <div class="form-group mb-40">
              <input type="text" class="form-control" id="id_password" placeholder="임시 비밀번호" value="V#GL9+B%05" readonly>
            </div>
            <router-link to="/login" class="btn btn-primary">로그인 페이지로 이동</router-link>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppTemporary',
}
</script>