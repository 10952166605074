import Vue from 'vue';
import App from './App.vue';
import Axios from 'axios';
import router from './router';
import store from './store';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
Vue.use(BootstrapVue);

// Custom Javscript
import './assets/mjs/main';
import Utils from './assets/js/custom.fed';
Vue.prototype.$utils = Utils;
Vue.use(Utils);

// eventBus
const eventBus = new Vue({
  // 메소드로 만들어 형제 컴포넌트에서 선언한 data 메소드화
  methods: {
    search(data) {
      this.$emit('search', data);
    },
    setValidation(data) {
      this.$emit('setValidation', data);
    },
    updateList(data) {
      this.$emit('updateList', data);
    },
    getImgList(data) {
      this.$emit('getImgList', data);
    },
    updateImgList(data) {
      this.$emit('updateImgList', data);
    },
    updateCurrentPage(data) {
      this.$emit('updateCurrentPage', data);
    },
  },
});
Vue.prototype.$eventBus = eventBus;
Vue.use(eventBus);

Vue.prototype.$expiration = 60;
Vue.config.productionTip = false;

// Axios
Vue.prototype.$axios = Axios;
// django CSRF
Axios.defaults.xsrfCookieName = 'csrftoken';
Axios.defaults.xsrfHeaderName = 'X-CSRFToken';

// 운영서버 ip 입력
Axios.defaults.baseURL = 'https://cms-test.ifood.care';
// 운영서버 API KEY
Axios.defaults.headers.common['api-key'] = 'ArWD3p8p.1uiiHjcBOjkZE0yPYIWRbMDPceKBj28p';

// 로컬 localhost ip 입력
// Axios.defaults.baseURL = 'http://127.0.0.1:8000';
// 로컬 API KEY
// Axios.defaults.headers.common['api-key'] = 'TmcwraaH.ppWQinbmZq0mvsIvshrDYPfhxn4ZUCnR';

new Vue({
  router,
  store,
  Axios,
  render: (h) => h(App),
}).$mount('#app');
