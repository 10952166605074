import Vue from 'vue';
import VueRouter from 'vue-router';

/**
 * User
 */
import Index from '@/views/Index.vue';
import Login from '@/views/user/Login.vue';
import Signup from '@/views/user/Signup.vue';
import Password from '@/views/user/Password.vue';
import Temporary from '@/views/user/Temporary.vue';

/**
 * Mypage
 */
import Mypage from '@/views/Mypage.vue';

/**
 * Food/Dish
 */
import ContentList from '@/views/content/List.vue';
import ContentDetail from '@/views/content/Detail.vue';

/**
 * Nutrient
 */
import NutrientList from '@/views/nutrient/List.vue';
import NutrientDetail from '@/views/nutrient/Detail.vue';

/**
 * Banner
 */
import BannerList from '@/views/banner/List.vue';
import BannerDetail from '@/views/banner/Detail.vue';
import BannerCreate from '@/views/banner/Create.vue';

/**
 * Error
 */
import NotFoundPage from '@/views/NotFoundPage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    component: NotFoundPage,
    name: 'pageNotFound',
  },
  {
    path: '/',
    component: Index,
    name: 'index',
  },
  {
    path: '/user/login',
    component: Login,
    name: 'login',
  },
  {
    path: '/user/signup',
    component: Signup,
    name: 'signup',
  },
  {
    path: '/user/password',
    component: Password,
    name: 'password',
  },
  {
    path: '/user/password/temporary',
    component: Temporary,
    name: 'temporary',
  },
  {
    path: '/mypage',
    component: Mypage,
    name: 'mypage',
  },
  {
    path: '/content/list',
    component: ContentList,
    name: 'contentList',
  },
  {
    path: '/content/detail/:contentType/:serviceId/:id',
    component: ContentDetail,
    name: 'contentDetail',
  },
  {
    path: '/nutrient/list',
    component: NutrientList,
    name: 'nutrientList',
  },
  {
    path: '/nutrient/detail/:serviceId/:id',
    component: NutrientDetail,
    name: 'nutrientDetail',
  },
  {
    path: '/banner/list',
    component: BannerList,
    name: 'bannerList',
  },
  {
    path: '/banner/detail/:serviceId/:id',
    component: BannerDetail,
    name: 'bannerDetail',
  },
  {
    path: '/banner/create/:serviceId',
    component: BannerCreate,
    name: 'bannerCreate',
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
